/* eslint-disable */
import { AspidaClient } from 'aspida'
import { Methods as Methods0 } from './accounts/email'
import { Methods as Methods1 } from './accounts/email/_id@number/sent_confirmation'
import { Methods as Methods2 } from './accounts/grant_points/grant_point_csv'
import { Methods as Methods3 } from './accounts/grant_points/validate_point_csv'
import { Methods as Methods4 } from './accounts/leave'
import { Methods as Methods5 } from './accounts/me'
import { Methods as Methods6 } from './accounts/me/bank_account'
import { Methods as Methods7 } from './accounts/me/is_staff'
import { Methods as Methods8 } from './accounts/me/qualifications'
import { Methods as Methods9 } from './accounts/me/qualifications/_id@number'
import { Methods as Methods10 } from './accounts/me/request_verification'
import { Methods as Methods11 } from './admin/abtest'
import { Methods as Methods12 } from './admin/abtest/_id@number'
import { Methods as Methods13 } from './admin/abtest/patch'
import { Methods as Methods14 } from './admin/abtest/statistical_result/_id@number'
import { Methods as Methods15 } from './admin/auth/login'
import { Methods as Methods16 } from './admin/corporations/global_corporation_announcements/available'
import { Methods as Methods17 } from './admin/global_corporation_announcements'
import { Methods as Methods18 } from './admin/global_corporation_announcements/_id@number'
import { Methods as Methods19 } from './admin/global_corporation_announcements/_id@number/disable'
import { Methods as Methods20 } from './admin/global_corporation_announcements/_id@number/enable'
import { Methods as Methods21 } from './admin/global_corporation_announcements/enabled'
import { Methods as Methods22 } from './admin/maintenance'
import { Methods as Methods23 } from './admin/workers'
import { Methods as Methods24 } from './admin/workers/bank_account'
import { Methods as Methods25 } from './admin/workers/bank_account/detail/_id@number'
import { Methods as Methods26 } from './admin/workers/detail/_id@string'
import { Methods as Methods27 } from './admin/workers/frozen'
import { Methods as Methods28 } from './admin/workers/qualification'
import { Methods as Methods29 } from './admin/workers/rejection'
import { Methods as Methods30 } from './admin/workers/verify'
import { Methods as Methods31 } from './auth/login'
import { Methods as Methods32 } from './auth/password/change'
import { Methods as Methods33 } from './auth/password/reset'
import { Methods as Methods34 } from './auth/password/reset/confirm'
import { Methods as Methods35 } from './auth/registration'
import { Methods as Methods36 } from './auth/registration/other_users'
import { Methods as Methods37 } from './auth/registration/validate_email'
import { Methods as Methods38 } from './auth/registration/verify-email'
import { Methods as Methods39 } from './auth/token/refresh'
import { Methods as Methods40 } from './auth/token/verify'
import { Methods as Methods41 } from './balances'
import { Methods as Methods42 } from './balances/history'
import { Methods as Methods43 } from './balances/request_withdraw'
import { Methods as Methods44 } from './corporations'
import { Methods as Methods45 } from './corporations/_id@number'
import { Methods as Methods46 } from './corporations/_id@number/areas'
import { Methods as Methods47 } from './corporations/_id@number/imported_corporation_black_lists'
import { Methods as Methods48 } from './corporations/_id@number/payroll_registers'
import { Methods as Methods49 } from './corporations/_id@number/payroll_registers/csv'
import { Methods as Methods50 } from './corporations/_id@number/payroll_registers/years'
import { Methods as Methods51 } from './corporations/admin_offices/bulk_create'
import { Methods as Methods52 } from './corporations/areas'
import { Methods as Methods53 } from './corporations/areas/_id@number'
import { Methods as Methods54 } from './corporations/areas/_id@number/link_offices'
import { Methods as Methods55 } from './corporations/areas/_id@number/link_users'
import { Methods as Methods56 } from './corporations/areas/delete'
import { Methods as Methods57 } from './corporations/billing'
import { Methods as Methods58 } from './corporations/billing/_billing_date@string/download_zip'
import { Methods as Methods59 } from './corporations/billing/_id@string/pdf_url'
import { Methods as Methods60 } from './corporations/billing/billing_badge_count'
import { Methods as Methods61 } from './corporations/billing/bulk_update_payment'
import { Methods as Methods62 } from './corporations/billing_date_list'
import { Methods as Methods63 } from './corporations/corporation_workers/_id@number'
import { Methods as Methods64 } from './corporations/office_workers'
import { Methods as Methods65 } from './corporations/office_workers/_id@number'
import { Methods as Methods66 } from './corporations/offices'
import { Methods as Methods67 } from './corporations/offices/_id@number'
import { Methods as Methods68 } from './corporations/offices/_id@number/minimum_hourly_wage'
import { Methods as Methods69 } from './corporations/offices/_id@number/reissue_office_code'
import { Methods as Methods70 } from './corporations/offices/_id@number/release_infection'
import { Methods as Methods71 } from './corporations/offices/_id@number/update_infection'
import { Methods as Methods72 } from './corporations/offices/_id@number/update_qr_comment'
import { Methods as Methods73 } from './corporations/offices/check_outbreaking_infection'
import { Methods as Methods74 } from './corporations/offices/infections'
import { Methods as Methods75 } from './corporations/recruitings/csv'
import { Methods as Methods76 } from './corporations/recruitings/search'
import { Methods as Methods77 } from './corporations/recruitings/update_status'
import { Methods as Methods78 } from './corporations/usage_detail_export_to_csv'
import { Methods as Methods79 } from './corporations/users'
import { Methods as Methods80 } from './corporations/users/_id@string'
import { Methods as Methods81 } from './corporations/users/_id@string/link_areas'
import { Methods as Methods82 } from './corporations/users/_id@string/link_offices'
import { Methods as Methods83 } from './corporations/users/delete'
import { Methods as Methods84 } from './corporations/workers'
import { Methods as Methods85 } from './corporations/workers/_id@string'
import { Methods as Methods86 } from './corporations/workers/_id@string/applied_offices'
import { Methods as Methods87 } from './corporations/workers/_id@string/connection_office_and_workers/_id@number'
import { Methods as Methods88 } from './corporations/workers/_id@string/favorite_or_block_statuses'
import { Methods as Methods89 } from './corporations/workers/_id@string/work_histories'
import { Methods as Methods90 } from './corporations/workers/_id@string/work_history_summary'
import { Methods as Methods91 } from './corporations/workers/download'
import { Methods as Methods92 } from './educations/training_for_corporations'
import { Methods as Methods93 } from './educations/training_for_corporations/_id@string'
import { Methods as Methods94 } from './educations/training_for_corporations/_id@string/download_csv'
import { Methods as Methods95 } from './educations/training_for_corporations/_id@string/update_status'
import { Methods as Methods96 } from './educations/training_for_corporations/_id@string/validate'
import { Methods as Methods97 } from './localities'
import { Methods as Methods98 } from './localities/_id@number'
import { Methods as Methods99 } from './localities/list_with_seirei'
import { Methods as Methods100 } from './orders'
import { Methods as Methods101 } from './orders/_id@number'
import { Methods as Methods102 } from './orders/_id@number/avg_evaluations'
import { Methods as Methods103 } from './orders/_id@number/update_applied_order'
import { Methods as Methods104 } from './orders/admin_orders'
import { Methods as Methods105 } from './orders/admin_orders/_id@number'
import { Methods as Methods106 } from './orders/admin_orders/batch_update_is_point_increased'
import { Methods as Methods107 } from './orders/admin_orders/batch_update_status'
import { Methods as Methods108 } from './orders/admin_orders/fetch_desired_work_schedules'
import { Methods as Methods109 } from './orders/admin_orders/fetch_is_first_time_work'
import { Methods as Methods110 } from './orders/admin_orders/fetch_order_with_applications'
import { Methods as Methods111 } from './orders/anonymous_evaluations/_officeId@number'
import { Methods as Methods112 } from './orders/anonymous_evaluations/_officeId@number/count'
import { Methods as Methods113 } from './orders/anonymous_evaluations/_officeId@number/update'
import { Methods as Methods114 } from './orders/anonymous_evaluations/_officeId@number/update/_evaluation_id@number'
import { Methods as Methods115 } from './orders/applications'
import { Methods as Methods116 } from './orders/applications/_id@number'
import { Methods as Methods117 } from './orders/applications/_id@number/application_conditions'
import { Methods as Methods118 } from './orders/applications/_id@number/cancel'
import { Methods as Methods119 } from './orders/applications/_id@number/finish_work'
import { Methods as Methods120 } from './orders/applications/_id@number/start_work'
import { Methods as Methods121 } from './orders/applications/can_apply'
import { Methods as Methods122 } from './orders/applications/count_tasks'
import { Methods as Methods123 } from './orders/applications/download_csv'
import { Methods as Methods124 } from './orders/applications/summary'
import { Methods as Methods125 } from './orders/attendance_corrections/_id@number/approve'
import { Methods as Methods126 } from './orders/attendance_corrections/_id@number/reject'
import { Methods as Methods127 } from './orders/calc_salary'
import { Methods as Methods128 } from './orders/cancel_confirmed'
import { Methods as Methods129 } from './orders/cancel_request'
import { Methods as Methods130 } from './orders/descriptions'
import { Methods as Methods131 } from './orders/evaluation_by_office'
import { Methods as Methods132 } from './orders/evaluation_by_office/download_csv'
import { Methods as Methods133 } from './orders/evaluation_by_office/summary'
import { Methods as Methods134 } from './orders/evaluation_by_worker'
import { Methods as Methods135 } from './orders/evaluation_by_worker/download_csv'
import { Methods as Methods136 } from './orders/evaluation_by_worker/summary'
import { Methods as Methods137 } from './orders/events'
import { Methods as Methods138 } from './orders/events/count_current'
import { Methods as Methods139 } from './orders/message_rooms'
import { Methods as Methods140 } from './orders/message_rooms/count_unread_messages_to_office'
import { Methods as Methods141 } from './orders/message_rooms/read_messages_to_office'
import { Methods as Methods142 } from './orders/minimum_rest_minutes'
import { Methods as Methods143 } from './orders/notice_of_employment_archive_list/_id@number'
import { Methods as Methods144 } from './orders/notice_of_employment_url/_id@number'
import { Methods as Methods145 } from './orders/notice_of_employment_url/_id@number/archive/_id@number'
import { Methods as Methods146 } from './orders/notice_of_employment_url/not_fixed'
import { Methods as Methods147 } from './orders/notice_of_employment_url/not_fixed_for_corporation'
import { Methods as Methods148 } from './orders/office_to_worker_application_messages'
import { Methods as Methods149 } from './orders/office_to_worker_application_messages/bulk_create'
import { Methods as Methods150 } from './orders/offices/_id@number/application_message_templates'
import { Methods as Methods151 } from './orders/offices/_id@number/application_message_templates/_id@number'
import { Methods as Methods152 } from './orders/offices/_id@number/evaluation_message_templates'
import { Methods as Methods153 } from './orders/offices/_id@number/evaluation_message_templates/_id@number'
import { Methods as Methods154 } from './orders/operations'
import { Methods as Methods155 } from './orders/preview_notice_of_employment/preview'
import { Methods as Methods156 } from './orders/templates'
import { Methods as Methods157 } from './orders/templates/_id@number'
import { Methods as Methods158 } from './orders/templates/_id@number/copy'
import { Methods as Methods159 } from './orders/templates/delete'
import { Methods as Methods160 } from './orders/total_avg_evaluations_by_office'
import { Methods as Methods161 } from './orders/total_avg_evaluations_by_worker'
import { Methods as Methods162 } from './orders/total_avg_evaluations_by_worker_list'
import { Methods as Methods163 } from './orders/work_history'
import { Methods as Methods164 } from './prefectures'
import { Methods as Methods165 } from './qualifications'
import { Methods as Methods166 } from './system/abtest/register_event'
import { Methods as Methods167 } from './system/event/register_event'
import { Methods as Methods168 } from './system/masters/all'
import { Methods as Methods169 } from './users'
import { Methods as Methods170 } from './users/_id@string'
import { Methods as Methods171 } from './withholdings/corporation/slips/eltax'
import { Methods as Methods172 } from './withholdings/corporation/slips/excel'
import { Methods as Methods173 } from './withholdings/corporation/slips/years'
import { Methods as Methods174 } from './workers/_id@string/cancel_rate'
import { Methods as Methods175 } from './workers/user_experience'
import { Methods as Methods176 } from './workers/work_count/_id@string'

const GET = 'GET'
const POST = 'POST'
const PUT = 'PUT'
const DELETE = 'DELETE'
const PATCH = 'PATCH'
const PATH0 = '/accounts/email/'
const PATH1 = '/sent_confirmation/'
const PATH2 = '/accounts/grant_points/grant_point_csv/'
const PATH3 = '/accounts/grant_points/validate_point_csv/'
const PATH4 = '/accounts/leave/'
const PATH5 = '/accounts/me/'
const PATH6 = '/accounts/me/bank_account/'
const PATH7 = '/accounts/me/is_staff/'
const PATH8 = '/accounts/me/qualifications/'
const PATH9 = '/'
const PATH10 = '/accounts/me/request_verification/'
const PATH11 = '/admin/abtest/'
const PATH12 = '/admin/abtest/patch/'
const PATH13 = '/admin/abtest/statistical_result/'
const PATH14 = '/admin/auth/login/'
const PATH15 = '/admin/corporations/global_corporation_announcements/available/'
const PATH16 = '/admin/global_corporation_announcements/'
const PATH17 = '/disable/'
const PATH18 = '/enable/'
const PATH19 = '/admin/global_corporation_announcements/enabled/'
const PATH20 = '/admin/maintenance/'
const PATH21 = '/admin/workers/'
const PATH22 = '/admin/workers/bank_account/'
const PATH23 = '/admin/workers/bank_account/detail/'
const PATH24 = '/admin/workers/detail/'
const PATH25 = '/admin/workers/frozen/'
const PATH26 = '/admin/workers/qualification/'
const PATH27 = '/admin/workers/rejection/'
const PATH28 = '/admin/workers/verify/'
const PATH29 = '/auth/login/'
const PATH30 = '/auth/password/change/'
const PATH31 = '/auth/password/reset/'
const PATH32 = '/auth/password/reset/confirm/'
const PATH33 = '/auth/registration/'
const PATH34 = '/auth/registration/other_users/'
const PATH35 = '/auth/registration/validate_email/'
const PATH36 = '/auth/registration/verify-email/'
const PATH37 = '/auth/token/refresh/'
const PATH38 = '/auth/token/verify/'
const PATH39 = '/balances/'
const PATH40 = '/balances/history/'
const PATH41 = '/balances/request_withdraw/'
const PATH42 = '/corporations/'
const PATH43 = '/areas/'
const PATH44 = '/imported_corporation_black_lists/'
const PATH45 = '/payroll_registers/'
const PATH46 = '/payroll_registers/csv/'
const PATH47 = '/payroll_registers/years/'
const PATH48 = '/corporations/admin_offices/bulk_create/'
const PATH49 = '/corporations/areas/'
const PATH50 = '/link_offices/'
const PATH51 = '/link_users/'
const PATH52 = '/corporations/areas/delete/'
const PATH53 = '/corporations/billing/'
const PATH54 = '/download_zip/'
const PATH55 = '/pdf_url/'
const PATH56 = '/corporations/billing/billing_badge_count/'
const PATH57 = '/corporations/billing/bulk_update_payment/'
const PATH58 = '/corporations/billing_date_list/'
const PATH59 = '/corporations/corporation_workers/'
const PATH60 = '/corporations/office_workers/'
const PATH61 = '/corporations/offices/'
const PATH62 = '/minimum_hourly_wage/'
const PATH63 = '/reissue_office_code/'
const PATH64 = '/release_infection/'
const PATH65 = '/update_infection/'
const PATH66 = '/update_qr_comment/'
const PATH67 = '/corporations/offices/check_outbreaking_infection/'
const PATH68 = '/corporations/offices/infections/'
const PATH69 = '/corporations/recruitings/csv/'
const PATH70 = '/corporations/recruitings/search/'
const PATH71 = '/corporations/recruitings/update_status/'
const PATH72 = '/corporations/usage_detail_export_to_csv/'
const PATH73 = '/corporations/users/'
const PATH74 = '/link_areas/'
const PATH75 = '/corporations/users/delete/'
const PATH76 = '/corporations/workers/'
const PATH77 = '/applied_offices/'
const PATH78 = '/connection_office_and_workers/'
const PATH79 = '/favorite_or_block_statuses/'
const PATH80 = '/work_histories/'
const PATH81 = '/work_history_summary/'
const PATH82 = '/corporations/workers/download/'
const PATH83 = '/educations/training_for_corporations/'
const PATH84 = '/download_csv/'
const PATH85 = '/update_status/'
const PATH86 = '/validate/'
const PATH87 = '/localities/'
const PATH88 = '/localities/list_with_seirei/'
const PATH89 = '/orders/'
const PATH90 = '/avg_evaluations/'
const PATH91 = '/update_applied_order/'
const PATH92 = '/orders/admin_orders/'
const PATH93 = '/orders/admin_orders/batch_update_is_point_increased/'
const PATH94 = '/orders/admin_orders/batch_update_status/'
const PATH95 = '/orders/admin_orders/fetch_desired_work_schedules/'
const PATH96 = '/orders/admin_orders/fetch_is_first_time_work/'
const PATH97 = '/orders/admin_orders/fetch_order_with_applications/'
const PATH98 = '/orders/anonymous_evaluations/'
const PATH99 = '/count/'
const PATH100 = '/update/'
const PATH101 = '/orders/applications/'
const PATH102 = '/application_conditions/'
const PATH103 = '/cancel/'
const PATH104 = '/finish_work/'
const PATH105 = '/start_work/'
const PATH106 = '/orders/applications/can_apply/'
const PATH107 = '/orders/applications/count_tasks/'
const PATH108 = '/orders/applications/download_csv/'
const PATH109 = '/orders/applications/summary/'
const PATH110 = '/orders/attendance_corrections/'
const PATH111 = '/approve/'
const PATH112 = '/reject/'
const PATH113 = '/orders/calc_salary/'
const PATH114 = '/orders/cancel_confirmed/'
const PATH115 = '/orders/cancel_request/'
const PATH116 = '/orders/descriptions/'
const PATH117 = '/orders/evaluation_by_office/'
const PATH118 = '/orders/evaluation_by_office/download_csv/'
const PATH119 = '/orders/evaluation_by_office/summary/'
const PATH120 = '/orders/evaluation_by_worker/'
const PATH121 = '/orders/evaluation_by_worker/download_csv/'
const PATH122 = '/orders/evaluation_by_worker/summary/'
const PATH123 = '/orders/events/'
const PATH124 = '/orders/events/count_current/'
const PATH125 = '/orders/message_rooms/'
const PATH126 = '/orders/message_rooms/count_unread_messages_to_office/'
const PATH127 = '/orders/message_rooms/read_messages_to_office/'
const PATH128 = '/orders/minimum_rest_minutes/'
const PATH129 = '/orders/notice_of_employment_archive_list/'
const PATH130 = '/orders/notice_of_employment_url/'
const PATH131 = '/archive/'
const PATH132 = '/orders/notice_of_employment_url/not_fixed/'
const PATH133 = '/orders/notice_of_employment_url/not_fixed_for_corporation/'
const PATH134 = '/orders/office_to_worker_application_messages/'
const PATH135 = '/orders/office_to_worker_application_messages/bulk_create/'
const PATH136 = '/orders/offices/'
const PATH137 = '/application_message_templates/'
const PATH138 = '/evaluation_message_templates/'
const PATH139 = '/orders/operations/'
const PATH140 = '/orders/preview_notice_of_employment/preview/'
const PATH141 = '/orders/templates/'
const PATH142 = '/copy/'
const PATH143 = '/orders/templates/delete/'
const PATH144 = '/orders/total_avg_evaluations_by_office/'
const PATH145 = '/orders/total_avg_evaluations_by_worker/'
const PATH146 = '/orders/total_avg_evaluations_by_worker_list/'
const PATH147 = '/orders/work_history/'
const PATH148 = '/prefectures/'
const PATH149 = '/qualifications/'
const PATH150 = '/system/abtest/register_event/'
const PATH151 = '/system/event/register_event/'
const PATH152 = '/system/masters/all/'
const PATH153 = '/users/'
const PATH154 = '/withholdings/corporation/slips/eltax/'
const PATH155 = '/withholdings/corporation/slips/excel/'
const PATH156 = '/withholdings/corporation/slips/years/'
const PATH157 = '/workers/'
const PATH158 = '/cancel_rate/'
const PATH159 = '/workers/user_experience/'
const PATH160 = '/workers/work_count/'
const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? '' : baseURL).replace(/\/$/, '')

  return {
    accounts: {
      email: {
        _id: (val0: number) => {
          const prefix0 = `${PATH0}${val0}`

          return {
            sent_confirmation: {
              post: (option?: { config?: T }) =>
                fetch<Methods1['post']['resBody']>(prefix, `${prefix0}${PATH1}`, POST, option).json(),
              $post: (option?: { config?: T }) =>
                fetch<Methods1['post']['resBody']>(prefix, `${prefix0}${PATH1}`, POST, option).json().then(r => r.body)
            }
          }
        },
        get: (option?: { query?: Methods0['get']['query'], config?: T }) =>
          fetch<Methods0['get']['resBody']>(prefix, PATH0, GET, option).json(),
        $get: (option?: { query?: Methods0['get']['query'], config?: T }) =>
          fetch<Methods0['get']['resBody']>(prefix, PATH0, GET, option).json().then(r => r.body),
        post: (option: { body: Methods0['post']['reqBody'], config?: T }) =>
          fetch<Methods0['post']['resBody']>(prefix, PATH0, POST, option).json(),
        $post: (option: { body: Methods0['post']['reqBody'], config?: T }) =>
          fetch<Methods0['post']['resBody']>(prefix, PATH0, POST, option).json().then(r => r.body)
      },
      grant_points: {
        grant_point_csv: {
          post: (option: { body: Methods2['post']['reqBody'], config?: T }) =>
            fetch<Methods2['post']['resBody']>(prefix, PATH2, POST, option).json(),
          $post: (option: { body: Methods2['post']['reqBody'], config?: T }) =>
            fetch<Methods2['post']['resBody']>(prefix, PATH2, POST, option).json().then(r => r.body)
        },
        validate_point_csv: {
          post: (option: { body: Methods3['post']['reqBody'], config?: T }) =>
            fetch<Methods3['post']['resBody']>(prefix, PATH3, POST, option).json(),
          $post: (option: { body: Methods3['post']['reqBody'], config?: T }) =>
            fetch<Methods3['post']['resBody']>(prefix, PATH3, POST, option).json().then(r => r.body)
        }
      },
      leave: {
        post: (option?: { config?: T }) =>
          fetch<Methods4['post']['resBody']>(prefix, PATH4, POST, option).json(),
        $post: (option?: { config?: T }) =>
          fetch<Methods4['post']['resBody']>(prefix, PATH4, POST, option).json().then(r => r.body)
      },
      me: {
        bank_account: {
          get: (option?: { config?: T }) =>
            fetch<Methods6['get']['resBody']>(prefix, PATH6, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods6['get']['resBody']>(prefix, PATH6, GET, option).json().then(r => r.body),
          post: (option: { body: Methods6['post']['reqBody'], config?: T }) =>
            fetch<Methods6['post']['resBody']>(prefix, PATH6, POST, option).json(),
          $post: (option: { body: Methods6['post']['reqBody'], config?: T }) =>
            fetch<Methods6['post']['resBody']>(prefix, PATH6, POST, option).json().then(r => r.body),
          patch: (option: { body: Methods6['patch']['reqBody'], config?: T }) =>
            fetch<Methods6['patch']['resBody']>(prefix, PATH6, PATCH, option).json(),
          $patch: (option: { body: Methods6['patch']['reqBody'], config?: T }) =>
            fetch<Methods6['patch']['resBody']>(prefix, PATH6, PATCH, option).json().then(r => r.body),
          delete: (option?: { config?: T }) =>
            fetch<void>(prefix, PATH6, DELETE, option).send(),
          $delete: (option?: { config?: T }) =>
            fetch<void>(prefix, PATH6, DELETE, option).send().then(r => r.body)
        },
        is_staff: {
          get: (option?: { config?: T }) =>
            fetch<Methods7['get']['resBody']>(prefix, PATH7, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods7['get']['resBody']>(prefix, PATH7, GET, option).json().then(r => r.body)
        },
        qualifications: {
          _id: (val1: number) => {
            const prefix1 = `${PATH8}${val1}`

            return {
              get: (option?: { config?: T }) =>
                fetch<Methods9['get']['resBody']>(prefix, `${prefix1}${PATH9}`, GET, option).json(),
              $get: (option?: { config?: T }) =>
                fetch<Methods9['get']['resBody']>(prefix, `${prefix1}${PATH9}`, GET, option).json().then(r => r.body),
              patch: (option: { body: Methods9['patch']['reqBody'], config?: T }) =>
                fetch<Methods9['patch']['resBody']>(prefix, `${prefix1}${PATH9}`, PATCH, option).json(),
              $patch: (option: { body: Methods9['patch']['reqBody'], config?: T }) =>
                fetch<Methods9['patch']['resBody']>(prefix, `${prefix1}${PATH9}`, PATCH, option).json().then(r => r.body),
              delete: (option?: { config?: T }) =>
                fetch<void>(prefix, `${prefix1}${PATH9}`, DELETE, option).send(),
              $delete: (option?: { config?: T }) =>
                fetch<void>(prefix, `${prefix1}${PATH9}`, DELETE, option).send().then(r => r.body)
            }
          },
          get: (option?: { query?: Methods8['get']['query'], config?: T }) =>
            fetch<Methods8['get']['resBody']>(prefix, PATH8, GET, option).json(),
          $get: (option?: { query?: Methods8['get']['query'], config?: T }) =>
            fetch<Methods8['get']['resBody']>(prefix, PATH8, GET, option).json().then(r => r.body),
          post: (option: { body: Methods8['post']['reqBody'], config?: T }) =>
            fetch<Methods8['post']['resBody']>(prefix, PATH8, POST, option).json(),
          $post: (option: { body: Methods8['post']['reqBody'], config?: T }) =>
            fetch<Methods8['post']['resBody']>(prefix, PATH8, POST, option).json().then(r => r.body)
        },
        request_verification: {
          post: (option?: { config?: T }) =>
            fetch<Methods10['post']['resBody']>(prefix, PATH10, POST, option).json(),
          $post: (option?: { config?: T }) =>
            fetch<Methods10['post']['resBody']>(prefix, PATH10, POST, option).json().then(r => r.body)
        },
        get: (option?: { config?: T }) =>
          fetch<Methods5['get']['resBody']>(prefix, PATH5, GET, option).json(),
        $get: (option?: { config?: T }) =>
          fetch<Methods5['get']['resBody']>(prefix, PATH5, GET, option).json().then(r => r.body),
        patch: (option: { body: Methods5['patch']['reqBody'], config?: T }) =>
          fetch<Methods5['patch']['resBody']>(prefix, PATH5, PATCH, option).json(),
        $patch: (option: { body: Methods5['patch']['reqBody'], config?: T }) =>
          fetch<Methods5['patch']['resBody']>(prefix, PATH5, PATCH, option).json().then(r => r.body)
      }
    },
    admin: {
      abtest: {
        _id: (val2: number) => {
          const prefix2 = `${PATH11}${val2}`

          return {
            get: (option?: { config?: T }) =>
              fetch<Methods12['get']['resBody']>(prefix, `${prefix2}${PATH9}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods12['get']['resBody']>(prefix, `${prefix2}${PATH9}`, GET, option).json().then(r => r.body)
          }
        },
        patch: {
          patch: (option: { body: Methods13['patch']['reqBody'], config?: T }) =>
            fetch<void>(prefix, PATH12, PATCH, option).send(),
          $patch: (option: { body: Methods13['patch']['reqBody'], config?: T }) =>
            fetch<void>(prefix, PATH12, PATCH, option).send().then(r => r.body)
        },
        statistical_result: {
          _id: (val3: number) => {
            const prefix3 = `${PATH13}${val3}`

            return {
              get: (option: { query: Methods14['get']['query'], config?: T }) =>
                fetch<Methods14['get']['resBody']>(prefix, `${prefix3}${PATH9}`, GET, option).json(),
              $get: (option: { query: Methods14['get']['query'], config?: T }) =>
                fetch<Methods14['get']['resBody']>(prefix, `${prefix3}${PATH9}`, GET, option).json().then(r => r.body)
            }
          }
        },
        get: (option?: { query?: Methods11['get']['query'], config?: T }) =>
          fetch<Methods11['get']['resBody']>(prefix, PATH11, GET, option).json(),
        $get: (option?: { query?: Methods11['get']['query'], config?: T }) =>
          fetch<Methods11['get']['resBody']>(prefix, PATH11, GET, option).json().then(r => r.body)
      },
      auth: {
        login: {
          post: (option: { body: Methods15['post']['reqBody'], config?: T }) =>
            fetch<Methods15['post']['resBody']>(prefix, PATH14, POST, option).json(),
          $post: (option: { body: Methods15['post']['reqBody'], config?: T }) =>
            fetch<Methods15['post']['resBody']>(prefix, PATH14, POST, option).json().then(r => r.body)
        }
      },
      corporations: {
        global_corporation_announcements: {
          available: {
            get: (option?: { config?: T }) =>
              fetch<Methods16['get']['resBody']>(prefix, PATH15, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods16['get']['resBody']>(prefix, PATH15, GET, option).json().then(r => r.body)
          }
        }
      },
      global_corporation_announcements: {
        _id: (val4: number) => {
          const prefix4 = `${PATH16}${val4}`

          return {
            disable: {
              put: (option?: { config?: T }) =>
                fetch<Methods19['put']['resBody']>(prefix, `${prefix4}${PATH17}`, PUT, option).json(),
              $put: (option?: { config?: T }) =>
                fetch<Methods19['put']['resBody']>(prefix, `${prefix4}${PATH17}`, PUT, option).json().then(r => r.body)
            },
            enable: {
              put: (option: { query: Methods20['put']['query'], config?: T }) =>
                fetch<Methods20['put']['resBody']>(prefix, `${prefix4}${PATH18}`, PUT, option).json(),
              $put: (option: { query: Methods20['put']['query'], config?: T }) =>
                fetch<Methods20['put']['resBody']>(prefix, `${prefix4}${PATH18}`, PUT, option).json().then(r => r.body)
            },
            get: (option?: { config?: T }) =>
              fetch<Methods18['get']['resBody']>(prefix, `${prefix4}${PATH9}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods18['get']['resBody']>(prefix, `${prefix4}${PATH9}`, GET, option).json().then(r => r.body),
            patch: (option: { body: Methods18['patch']['reqBody'], config?: T }) =>
              fetch<Methods18['patch']['resBody']>(prefix, `${prefix4}${PATH9}`, PATCH, option).json(),
            $patch: (option: { body: Methods18['patch']['reqBody'], config?: T }) =>
              fetch<Methods18['patch']['resBody']>(prefix, `${prefix4}${PATH9}`, PATCH, option).json().then(r => r.body),
            delete: (option?: { config?: T }) =>
              fetch<void>(prefix, `${prefix4}${PATH9}`, DELETE, option).send(),
            $delete: (option?: { config?: T }) =>
              fetch<void>(prefix, `${prefix4}${PATH9}`, DELETE, option).send().then(r => r.body)
          }
        },
        enabled: {
          get: (option?: { config?: T }) =>
            fetch<Methods21['get']['resBody']>(prefix, PATH19, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods21['get']['resBody']>(prefix, PATH19, GET, option).json().then(r => r.body)
        },
        get: (option?: { query?: Methods17['get']['query'], config?: T }) =>
          fetch<Methods17['get']['resBody']>(prefix, PATH16, GET, option).json(),
        $get: (option?: { query?: Methods17['get']['query'], config?: T }) =>
          fetch<Methods17['get']['resBody']>(prefix, PATH16, GET, option).json().then(r => r.body),
        post: (option: { body: Methods17['post']['reqBody'], config?: T }) =>
          fetch<Methods17['post']['resBody']>(prefix, PATH16, POST, option).json(),
        $post: (option: { body: Methods17['post']['reqBody'], config?: T }) =>
          fetch<Methods17['post']['resBody']>(prefix, PATH16, POST, option).json().then(r => r.body)
      },
      maintenance: {
        post: (option?: { config?: T }) =>
          fetch<Methods22['post']['resBody']>(prefix, PATH20, POST, option).json(),
        $post: (option?: { config?: T }) =>
          fetch<Methods22['post']['resBody']>(prefix, PATH20, POST, option).json().then(r => r.body)
      },
      workers: {
        bank_account: {
          detail: {
            _id: (val5: number) => {
              const prefix5 = `${PATH23}${val5}`

              return {
                get: (option?: { config?: T }) =>
                  fetch<Methods25['get']['resBody']>(prefix, `${prefix5}${PATH9}`, GET, option).json(),
                $get: (option?: { config?: T }) =>
                  fetch<Methods25['get']['resBody']>(prefix, `${prefix5}${PATH9}`, GET, option).json().then(r => r.body),
                patch: (option: { body: Methods25['patch']['reqBody'], config?: T }) =>
                  fetch<Methods25['patch']['resBody']>(prefix, `${prefix5}${PATH9}`, PATCH, option).json(),
                $patch: (option: { body: Methods25['patch']['reqBody'], config?: T }) =>
                  fetch<Methods25['patch']['resBody']>(prefix, `${prefix5}${PATH9}`, PATCH, option).json().then(r => r.body)
              }
            }
          },
          get: (option?: { query?: Methods24['get']['query'], config?: T }) =>
            fetch<Methods24['get']['resBody']>(prefix, PATH22, GET, option).json(),
          $get: (option?: { query?: Methods24['get']['query'], config?: T }) =>
            fetch<Methods24['get']['resBody']>(prefix, PATH22, GET, option).json().then(r => r.body)
        },
        detail: {
          _id: (val6: string) => {
            const prefix6 = `${PATH24}${val6}`

            return {
              get: (option?: { config?: T }) =>
                fetch<Methods26['get']['resBody']>(prefix, `${prefix6}${PATH9}`, GET, option).json(),
              $get: (option?: { config?: T }) =>
                fetch<Methods26['get']['resBody']>(prefix, `${prefix6}${PATH9}`, GET, option).json().then(r => r.body),
              patch: (option: { body: Methods26['patch']['reqBody'], config?: T }) =>
                fetch<Methods26['patch']['resBody']>(prefix, `${prefix6}${PATH9}`, PATCH, option).json(),
              $patch: (option: { body: Methods26['patch']['reqBody'], config?: T }) =>
                fetch<Methods26['patch']['resBody']>(prefix, `${prefix6}${PATH9}`, PATCH, option).json().then(r => r.body)
            }
          }
        },
        frozen: {
          post: (option: { body: Methods27['post']['reqBody'], config?: T }) =>
            fetch<Methods27['post']['resBody']>(prefix, PATH25, POST, option).json(),
          $post: (option: { body: Methods27['post']['reqBody'], config?: T }) =>
            fetch<Methods27['post']['resBody']>(prefix, PATH25, POST, option).json().then(r => r.body)
        },
        qualification: {
          get: (option?: { query?: Methods28['get']['query'], config?: T }) =>
            fetch<Methods28['get']['resBody']>(prefix, PATH26, GET, option).json(),
          $get: (option?: { query?: Methods28['get']['query'], config?: T }) =>
            fetch<Methods28['get']['resBody']>(prefix, PATH26, GET, option).json().then(r => r.body)
        },
        rejection: {
          post: (option: { body: Methods29['post']['reqBody'], config?: T }) =>
            fetch<Methods29['post']['resBody']>(prefix, PATH27, POST, option).json(),
          $post: (option: { body: Methods29['post']['reqBody'], config?: T }) =>
            fetch<Methods29['post']['resBody']>(prefix, PATH27, POST, option).json().then(r => r.body)
        },
        verify: {
          post: (option: { body: Methods30['post']['reqBody'], config?: T }) =>
            fetch<Methods30['post']['resBody']>(prefix, PATH28, POST, option).json(),
          $post: (option: { body: Methods30['post']['reqBody'], config?: T }) =>
            fetch<Methods30['post']['resBody']>(prefix, PATH28, POST, option).json().then(r => r.body)
        },
        get: (option?: { query?: Methods23['get']['query'], config?: T }) =>
          fetch<Methods23['get']['resBody']>(prefix, PATH21, GET, option).json(),
        $get: (option?: { query?: Methods23['get']['query'], config?: T }) =>
          fetch<Methods23['get']['resBody']>(prefix, PATH21, GET, option).json().then(r => r.body)
      }
    },
    auth: {
      login: {
        post: (option: { body: Methods31['post']['reqBody'], config?: T }) =>
          fetch<Methods31['post']['resBody']>(prefix, PATH29, POST, option).json(),
        $post: (option: { body: Methods31['post']['reqBody'], config?: T }) =>
          fetch<Methods31['post']['resBody']>(prefix, PATH29, POST, option).json().then(r => r.body)
      },
      password: {
        change: {
          post: (option: { body: Methods32['post']['reqBody'], config?: T }) =>
            fetch<Methods32['post']['resBody']>(prefix, PATH30, POST, option).json(),
          $post: (option: { body: Methods32['post']['reqBody'], config?: T }) =>
            fetch<Methods32['post']['resBody']>(prefix, PATH30, POST, option).json().then(r => r.body)
        },
        reset: {
          confirm: {
            post: (option: { body: Methods34['post']['reqBody'], config?: T }) =>
              fetch<Methods34['post']['resBody']>(prefix, PATH32, POST, option).json(),
            $post: (option: { body: Methods34['post']['reqBody'], config?: T }) =>
              fetch<Methods34['post']['resBody']>(prefix, PATH32, POST, option).json().then(r => r.body)
          },
          post: (option: { body: Methods33['post']['reqBody'], config?: T }) =>
            fetch<void>(prefix, PATH31, POST, option).send(),
          $post: (option: { body: Methods33['post']['reqBody'], config?: T }) =>
            fetch<void>(prefix, PATH31, POST, option).send().then(r => r.body)
        }
      },
      registration: {
        other_users: {
          post: (option: { body: Methods36['post']['reqBody'], config?: T }) =>
            fetch<Methods36['post']['resBody']>(prefix, PATH34, POST, option).json(),
          $post: (option: { body: Methods36['post']['reqBody'], config?: T }) =>
            fetch<Methods36['post']['resBody']>(prefix, PATH34, POST, option).json().then(r => r.body)
        },
        validate_email: {
          post: (option: { body: Methods37['post']['reqBody'], config?: T }) =>
            fetch<Methods37['post']['resBody']>(prefix, PATH35, POST, option).json(),
          $post: (option: { body: Methods37['post']['reqBody'], config?: T }) =>
            fetch<Methods37['post']['resBody']>(prefix, PATH35, POST, option).json().then(r => r.body)
        },
        verify_email: {
          post: (option: { body: Methods38['post']['reqBody'], config?: T }) =>
            fetch<Methods38['post']['resBody']>(prefix, PATH36, POST, option).json(),
          $post: (option: { body: Methods38['post']['reqBody'], config?: T }) =>
            fetch<Methods38['post']['resBody']>(prefix, PATH36, POST, option).json().then(r => r.body)
        },
        post: (option: { body: Methods35['post']['reqBody'], config?: T }) =>
          fetch<Methods35['post']['resBody']>(prefix, PATH33, POST, option).json(),
        $post: (option: { body: Methods35['post']['reqBody'], config?: T }) =>
          fetch<Methods35['post']['resBody']>(prefix, PATH33, POST, option).json().then(r => r.body)
      },
      token: {
        refresh: {
          post: (option: { body: Methods39['post']['reqBody'], config?: T }) =>
            fetch<Methods39['post']['resBody']>(prefix, PATH37, POST, option).json(),
          $post: (option: { body: Methods39['post']['reqBody'], config?: T }) =>
            fetch<Methods39['post']['resBody']>(prefix, PATH37, POST, option).json().then(r => r.body)
        },
        verify: {
          post: (option: { body: Methods40['post']['reqBody'], config?: T }) =>
            fetch<Methods40['post']['resBody']>(prefix, PATH38, POST, option).json(),
          $post: (option: { body: Methods40['post']['reqBody'], config?: T }) =>
            fetch<Methods40['post']['resBody']>(prefix, PATH38, POST, option).json().then(r => r.body)
        }
      }
    },
    balances: {
      history: {
        get: (option?: { query?: Methods42['get']['query'], config?: T }) =>
          fetch<Methods42['get']['resBody']>(prefix, PATH40, GET, option).json(),
        $get: (option?: { query?: Methods42['get']['query'], config?: T }) =>
          fetch<Methods42['get']['resBody']>(prefix, PATH40, GET, option).json().then(r => r.body)
      },
      request_withdraw: {
        post: (option: { body: Methods43['post']['reqBody'], config?: T }) =>
          fetch<Methods43['post']['resBody']>(prefix, PATH41, POST, option).json(),
        $post: (option: { body: Methods43['post']['reqBody'], config?: T }) =>
          fetch<Methods43['post']['resBody']>(prefix, PATH41, POST, option).json().then(r => r.body)
      },
      get: (option?: { config?: T }) =>
        fetch<Methods41['get']['resBody']>(prefix, PATH39, GET, option).json(),
      $get: (option?: { config?: T }) =>
        fetch<Methods41['get']['resBody']>(prefix, PATH39, GET, option).json().then(r => r.body)
    },
    corporations: {
      _id: (val7: number) => {
        const prefix7 = `${PATH42}${val7}`

        return {
          areas: {
            get: (option?: { config?: T }) =>
              fetch<Methods46['get']['resBody']>(prefix, `${prefix7}${PATH43}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods46['get']['resBody']>(prefix, `${prefix7}${PATH43}`, GET, option).json().then(r => r.body)
          },
          imported_corporation_black_lists: {
            post: (option: { body: Methods47['post']['reqBody'], config?: T }) =>
              fetch<Methods47['post']['resBody']>(prefix, `${prefix7}${PATH44}`, POST, option).json(),
            $post: (option: { body: Methods47['post']['reqBody'], config?: T }) =>
              fetch<Methods47['post']['resBody']>(prefix, `${prefix7}${PATH44}`, POST, option).json().then(r => r.body)
          },
          payroll_registers: {
            csv: {
              get: (option: { query: Methods49['get']['query'], config?: T }) =>
                fetch<Methods49['get']['resBody']>(prefix, `${prefix7}${PATH46}`, GET, option).blob(),
              $get: (option: { query: Methods49['get']['query'], config?: T }) =>
                fetch<Methods49['get']['resBody']>(prefix, `${prefix7}${PATH46}`, GET, option).blob().then(r => r.body),
              post: (option: { body: Methods49['post']['reqBody'], config?: T }) =>
                fetch<void>(prefix, `${prefix7}${PATH46}`, POST, option).send(),
              $post: (option: { body: Methods49['post']['reqBody'], config?: T }) =>
                fetch<void>(prefix, `${prefix7}${PATH46}`, POST, option).send().then(r => r.body)
            },
            years: {
              get: (option?: { config?: T }) =>
                fetch<Methods50['get']['resBody']>(prefix, `${prefix7}${PATH47}`, GET, option).json(),
              $get: (option?: { config?: T }) =>
                fetch<Methods50['get']['resBody']>(prefix, `${prefix7}${PATH47}`, GET, option).json().then(r => r.body)
            },
            get: (option: { query: Methods48['get']['query'], config?: T }) =>
              fetch<Methods48['get']['resBody']>(prefix, `${prefix7}${PATH45}`, GET, option).json(),
            $get: (option: { query: Methods48['get']['query'], config?: T }) =>
              fetch<Methods48['get']['resBody']>(prefix, `${prefix7}${PATH45}`, GET, option).json().then(r => r.body)
          },
          get: (option?: { config?: T }) =>
            fetch<Methods45['get']['resBody']>(prefix, `${prefix7}${PATH9}`, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods45['get']['resBody']>(prefix, `${prefix7}${PATH9}`, GET, option).json().then(r => r.body),
          post: (option: { body: Methods45['post']['reqBody'], config?: T }) =>
            fetch<Methods45['post']['resBody']>(prefix, `${prefix7}${PATH9}`, POST, option).json(),
          $post: (option: { body: Methods45['post']['reqBody'], config?: T }) =>
            fetch<Methods45['post']['resBody']>(prefix, `${prefix7}${PATH9}`, POST, option).json().then(r => r.body),
          patch: (option: { body: Methods45['patch']['reqBody'], config?: T }) =>
            fetch<Methods45['patch']['resBody']>(prefix, `${prefix7}${PATH9}`, PATCH, option).json(),
          $patch: (option: { body: Methods45['patch']['reqBody'], config?: T }) =>
            fetch<Methods45['patch']['resBody']>(prefix, `${prefix7}${PATH9}`, PATCH, option).json().then(r => r.body)
        }
      },
      admin_offices: {
        bulk_create: {
          post: (option: { body: Methods51['post']['reqBody'], config?: T }) =>
            fetch<Methods51['post']['resBody']>(prefix, PATH48, POST, option).json(),
          $post: (option: { body: Methods51['post']['reqBody'], config?: T }) =>
            fetch<Methods51['post']['resBody']>(prefix, PATH48, POST, option).json().then(r => r.body)
        }
      },
      areas: {
        _id: (val8: number) => {
          const prefix8 = `${PATH49}${val8}`

          return {
            link_offices: {
              post: (option: { body: Methods54['post']['reqBody'], config?: T }) =>
                fetch<Methods54['post']['resBody']>(prefix, `${prefix8}${PATH50}`, POST, option).json(),
              $post: (option: { body: Methods54['post']['reqBody'], config?: T }) =>
                fetch<Methods54['post']['resBody']>(prefix, `${prefix8}${PATH50}`, POST, option).json().then(r => r.body)
            },
            link_users: {
              post: (option: { body: Methods55['post']['reqBody'], config?: T }) =>
                fetch<Methods55['post']['resBody']>(prefix, `${prefix8}${PATH51}`, POST, option).json(),
              $post: (option: { body: Methods55['post']['reqBody'], config?: T }) =>
                fetch<Methods55['post']['resBody']>(prefix, `${prefix8}${PATH51}`, POST, option).json().then(r => r.body)
            },
            get: (option?: { config?: T }) =>
              fetch<Methods53['get']['resBody']>(prefix, `${prefix8}${PATH9}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods53['get']['resBody']>(prefix, `${prefix8}${PATH9}`, GET, option).json().then(r => r.body),
            patch: (option: { body: Methods53['patch']['reqBody'], config?: T }) =>
              fetch<Methods53['patch']['resBody']>(prefix, `${prefix8}${PATH9}`, PATCH, option).json(),
            $patch: (option: { body: Methods53['patch']['reqBody'], config?: T }) =>
              fetch<Methods53['patch']['resBody']>(prefix, `${prefix8}${PATH9}`, PATCH, option).json().then(r => r.body),
            delete: (option?: { config?: T }) =>
              fetch<void>(prefix, `${prefix8}${PATH9}`, DELETE, option).send(),
            $delete: (option?: { config?: T }) =>
              fetch<void>(prefix, `${prefix8}${PATH9}`, DELETE, option).send().then(r => r.body)
          }
        },
        delete: {
          post: (option: { body: Methods56['post']['reqBody'], config?: T }) =>
            fetch<Methods56['post']['resBody']>(prefix, PATH52, POST, option).json(),
          $post: (option: { body: Methods56['post']['reqBody'], config?: T }) =>
            fetch<Methods56['post']['resBody']>(prefix, PATH52, POST, option).json().then(r => r.body)
        },
        get: (option?: { query?: Methods52['get']['query'], config?: T }) =>
          fetch<Methods52['get']['resBody']>(prefix, PATH49, GET, option).json(),
        $get: (option?: { query?: Methods52['get']['query'], config?: T }) =>
          fetch<Methods52['get']['resBody']>(prefix, PATH49, GET, option).json().then(r => r.body),
        post: (option: { body: Methods52['post']['reqBody'], config?: T }) =>
          fetch<Methods52['post']['resBody']>(prefix, PATH49, POST, option).json(),
        $post: (option: { body: Methods52['post']['reqBody'], config?: T }) =>
          fetch<Methods52['post']['resBody']>(prefix, PATH49, POST, option).json().then(r => r.body)
      },
      billing: {
        _billing_date: (val9: string) => {
          const prefix9 = `${PATH53}${val9}`

          return {
            download_zip: {
              get: (option?: { query?: Methods58['get']['query'], config?: T }) =>
                fetch<Methods58['get']['resBody']>(prefix, `${prefix9}${PATH54}`, GET, option).blob(),
              $get: (option?: { query?: Methods58['get']['query'], config?: T }) =>
                fetch<Methods58['get']['resBody']>(prefix, `${prefix9}${PATH54}`, GET, option).blob().then(r => r.body)
            }
          }
        },
        _id: (val10: string) => {
          const prefix10 = `${PATH53}${val10}`

          return {
            pdf_url: {
              get: (option?: { config?: T }) =>
                fetch<Methods59['get']['resBody']>(prefix, `${prefix10}${PATH55}`, GET, option).json(),
              $get: (option?: { config?: T }) =>
                fetch<Methods59['get']['resBody']>(prefix, `${prefix10}${PATH55}`, GET, option).json().then(r => r.body)
            }
          }
        },
        billing_badge_count: {
          get: (option?: { config?: T }) =>
            fetch<Methods60['get']['resBody']>(prefix, PATH56, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods60['get']['resBody']>(prefix, PATH56, GET, option).json().then(r => r.body)
        },
        bulk_update_payment: {
          post: (option: { body: Methods61['post']['reqBody'], config?: T }) =>
            fetch<Methods61['post']['resBody']>(prefix, PATH57, POST, option).json(),
          $post: (option: { body: Methods61['post']['reqBody'], config?: T }) =>
            fetch<Methods61['post']['resBody']>(prefix, PATH57, POST, option).json().then(r => r.body)
        },
        get: (option?: { query?: Methods57['get']['query'], config?: T }) =>
          fetch<Methods57['get']['resBody']>(prefix, PATH53, GET, option).json(),
        $get: (option?: { query?: Methods57['get']['query'], config?: T }) =>
          fetch<Methods57['get']['resBody']>(prefix, PATH53, GET, option).json().then(r => r.body)
      },
      billing_date_list: {
        get: (option?: { config?: T }) =>
          fetch<Methods62['get']['resBody']>(prefix, PATH58, GET, option).json(),
        $get: (option?: { config?: T }) =>
          fetch<Methods62['get']['resBody']>(prefix, PATH58, GET, option).json().then(r => r.body)
      },
      corporation_workers: {
        _id: (val11: number) => {
          const prefix11 = `${PATH59}${val11}`

          return {
            patch: (option: { body: Methods63['patch']['reqBody'], config?: T }) =>
              fetch<void>(prefix, `${prefix11}${PATH9}`, PATCH, option).send(),
            $patch: (option: { body: Methods63['patch']['reqBody'], config?: T }) =>
              fetch<void>(prefix, `${prefix11}${PATH9}`, PATCH, option).send().then(r => r.body)
          }
        }
      },
      office_workers: {
        _id: (val12: number) => {
          const prefix12 = `${PATH60}${val12}`

          return {
            patch: (option: { body: Methods65['patch']['reqBody'], config?: T }) =>
              fetch<void>(prefix, `${prefix12}${PATH9}`, PATCH, option).send(),
            $patch: (option: { body: Methods65['patch']['reqBody'], config?: T }) =>
              fetch<void>(prefix, `${prefix12}${PATH9}`, PATCH, option).send().then(r => r.body)
          }
        },
        get: (option?: { query?: Methods64['get']['query'], config?: T }) =>
          fetch<Methods64['get']['resBody']>(prefix, PATH60, GET, option).json(),
        $get: (option?: { query?: Methods64['get']['query'], config?: T }) =>
          fetch<Methods64['get']['resBody']>(prefix, PATH60, GET, option).json().then(r => r.body)
      },
      offices: {
        _id: (val13: number) => {
          const prefix13 = `${PATH61}${val13}`

          return {
            minimum_hourly_wage: {
              get: (option?: { config?: T }) =>
                fetch<Methods68['get']['resBody']>(prefix, `${prefix13}${PATH62}`, GET, option).json(),
              $get: (option?: { config?: T }) =>
                fetch<Methods68['get']['resBody']>(prefix, `${prefix13}${PATH62}`, GET, option).json().then(r => r.body)
            },
            reissue_office_code: {
              patch: (option?: { config?: T }) =>
                fetch<Methods69['patch']['resBody']>(prefix, `${prefix13}${PATH63}`, PATCH, option).json(),
              $patch: (option?: { config?: T }) =>
                fetch<Methods69['patch']['resBody']>(prefix, `${prefix13}${PATH63}`, PATCH, option).json().then(r => r.body)
            },
            release_infection: {
              patch: (option: { body: Methods70['patch']['reqBody'], config?: T }) =>
                fetch<void>(prefix, `${prefix13}${PATH64}`, PATCH, option).send(),
              $patch: (option: { body: Methods70['patch']['reqBody'], config?: T }) =>
                fetch<void>(prefix, `${prefix13}${PATH64}`, PATCH, option).send().then(r => r.body)
            },
            update_infection: {
              post: (option: { body: Methods71['post']['reqBody'], config?: T }) =>
                fetch<void>(prefix, `${prefix13}${PATH65}`, POST, option).send(),
              $post: (option: { body: Methods71['post']['reqBody'], config?: T }) =>
                fetch<void>(prefix, `${prefix13}${PATH65}`, POST, option).send().then(r => r.body)
            },
            update_qr_comment: {
              patch: (option: { body: Methods72['patch']['reqBody'], config?: T }) =>
                fetch<Methods72['patch']['resBody']>(prefix, `${prefix13}${PATH66}`, PATCH, option).json(),
              $patch: (option: { body: Methods72['patch']['reqBody'], config?: T }) =>
                fetch<Methods72['patch']['resBody']>(prefix, `${prefix13}${PATH66}`, PATCH, option).json().then(r => r.body)
            },
            get: (option?: { config?: T }) =>
              fetch<Methods67['get']['resBody']>(prefix, `${prefix13}${PATH9}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods67['get']['resBody']>(prefix, `${prefix13}${PATH9}`, GET, option).json().then(r => r.body),
            patch: (option: { body: Methods67['patch']['reqBody'], config?: T }) =>
              fetch<Methods67['patch']['resBody']>(prefix, `${prefix13}${PATH9}`, PATCH, option).json(),
            $patch: (option: { body: Methods67['patch']['reqBody'], config?: T }) =>
              fetch<Methods67['patch']['resBody']>(prefix, `${prefix13}${PATH9}`, PATCH, option).json().then(r => r.body)
          }
        },
        check_outbreaking_infection: {
          get: (option: { query: Methods73['get']['query'], config?: T }) =>
            fetch<Methods73['get']['resBody']>(prefix, PATH67, GET, option).json(),
          $get: (option: { query: Methods73['get']['query'], config?: T }) =>
            fetch<Methods73['get']['resBody']>(prefix, PATH67, GET, option).json().then(r => r.body)
        },
        infections: {
          get: (option?: { config?: T }) =>
            fetch<Methods74['get']['resBody']>(prefix, PATH68, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods74['get']['resBody']>(prefix, PATH68, GET, option).json().then(r => r.body)
        },
        get: (option?: { query?: Methods66['get']['query'], config?: T }) =>
          fetch<Methods66['get']['resBody']>(prefix, PATH61, GET, option).json(),
        $get: (option?: { query?: Methods66['get']['query'], config?: T }) =>
          fetch<Methods66['get']['resBody']>(prefix, PATH61, GET, option).json().then(r => r.body),
        post: (option: { body: Methods66['post']['reqBody'], config?: T }) =>
          fetch<Methods66['post']['resBody']>(prefix, PATH61, POST, option).json(),
        $post: (option: { body: Methods66['post']['reqBody'], config?: T }) =>
          fetch<Methods66['post']['resBody']>(prefix, PATH61, POST, option).json().then(r => r.body)
      },
      recruitings: {
        csv: {
          get: (option: { query: Methods75['get']['query'], config?: T }) =>
            fetch<Methods75['get']['resBody']>(prefix, PATH69, GET, option).blob(),
          $get: (option: { query: Methods75['get']['query'], config?: T }) =>
            fetch<Methods75['get']['resBody']>(prefix, PATH69, GET, option).blob().then(r => r.body)
        },
        search: {
          get: (option: { query: Methods76['get']['query'], config?: T }) =>
            fetch<Methods76['get']['resBody']>(prefix, PATH70, GET, option).json(),
          $get: (option: { query: Methods76['get']['query'], config?: T }) =>
            fetch<Methods76['get']['resBody']>(prefix, PATH70, GET, option).json().then(r => r.body)
        },
        update_status: {
          patch: (option: { body: Methods77['patch']['reqBody'], config?: T }) =>
            fetch<Methods77['patch']['resBody']>(prefix, PATH71, PATCH, option).json(),
          $patch: (option: { body: Methods77['patch']['reqBody'], config?: T }) =>
            fetch<Methods77['patch']['resBody']>(prefix, PATH71, PATCH, option).json().then(r => r.body)
        }
      },
      usage_detail_export_to_csv: {
        post: (option?: { body?: Methods78['post']['reqBody'], config?: T }) =>
          fetch<Methods78['post']['resBody']>(prefix, PATH72, POST, option).blob(),
        $post: (option?: { body?: Methods78['post']['reqBody'], config?: T }) =>
          fetch<Methods78['post']['resBody']>(prefix, PATH72, POST, option).blob().then(r => r.body)
      },
      users: {
        _id: (val14: string) => {
          const prefix14 = `${PATH73}${val14}`

          return {
            link_areas: {
              post: (option: { body: Methods81['post']['reqBody'], config?: T }) =>
                fetch<Methods81['post']['resBody']>(prefix, `${prefix14}${PATH74}`, POST, option).json(),
              $post: (option: { body: Methods81['post']['reqBody'], config?: T }) =>
                fetch<Methods81['post']['resBody']>(prefix, `${prefix14}${PATH74}`, POST, option).json().then(r => r.body)
            },
            link_offices: {
              post: (option: { body: Methods82['post']['reqBody'], config?: T }) =>
                fetch<Methods82['post']['resBody']>(prefix, `${prefix14}${PATH50}`, POST, option).json(),
              $post: (option: { body: Methods82['post']['reqBody'], config?: T }) =>
                fetch<Methods82['post']['resBody']>(prefix, `${prefix14}${PATH50}`, POST, option).json().then(r => r.body)
            },
            get: (option?: { config?: T }) =>
              fetch<Methods80['get']['resBody']>(prefix, `${prefix14}${PATH9}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods80['get']['resBody']>(prefix, `${prefix14}${PATH9}`, GET, option).json().then(r => r.body),
            patch: (option: { body: Methods80['patch']['reqBody'], config?: T }) =>
              fetch<Methods80['patch']['resBody']>(prefix, `${prefix14}${PATH9}`, PATCH, option).json(),
            $patch: (option: { body: Methods80['patch']['reqBody'], config?: T }) =>
              fetch<Methods80['patch']['resBody']>(prefix, `${prefix14}${PATH9}`, PATCH, option).json().then(r => r.body)
          }
        },
        delete: {
          post: (option: { body: Methods83['post']['reqBody'], config?: T }) =>
            fetch<Methods83['post']['resBody']>(prefix, PATH75, POST, option).json(),
          $post: (option: { body: Methods83['post']['reqBody'], config?: T }) =>
            fetch<Methods83['post']['resBody']>(prefix, PATH75, POST, option).json().then(r => r.body)
        },
        get: (option?: { query?: Methods79['get']['query'], config?: T }) =>
          fetch<Methods79['get']['resBody']>(prefix, PATH73, GET, option).json(),
        $get: (option?: { query?: Methods79['get']['query'], config?: T }) =>
          fetch<Methods79['get']['resBody']>(prefix, PATH73, GET, option).json().then(r => r.body),
        post: (option: { body: Methods79['post']['reqBody'], config?: T }) =>
          fetch<Methods79['post']['resBody']>(prefix, PATH73, POST, option).json(),
        $post: (option: { body: Methods79['post']['reqBody'], config?: T }) =>
          fetch<Methods79['post']['resBody']>(prefix, PATH73, POST, option).json().then(r => r.body)
      },
      workers: {
        _id: (val15: string) => {
          const prefix15 = `${PATH76}${val15}`

          return {
            applied_offices: {
              get: (option?: { config?: T }) =>
                fetch<Methods86['get']['resBody']>(prefix, `${prefix15}${PATH77}`, GET, option).json(),
              $get: (option?: { config?: T }) =>
                fetch<Methods86['get']['resBody']>(prefix, `${prefix15}${PATH77}`, GET, option).json().then(r => r.body)
            },
            connection_office_and_workers: {
              _id: (val16: number) => {
                const prefix16 = `${prefix15}${PATH78}${val16}`

                return {
                  get: (option?: { config?: T }) =>
                    fetch<Methods87['get']['resBody']>(prefix, `${prefix16}${PATH9}`, GET, option).json(),
                  $get: (option?: { config?: T }) =>
                    fetch<Methods87['get']['resBody']>(prefix, `${prefix16}${PATH9}`, GET, option).json().then(r => r.body)
                }
              }
            },
            favorite_or_block_statuses: {
              get: (option?: { config?: T }) =>
                fetch<Methods88['get']['resBody']>(prefix, `${prefix15}${PATH79}`, GET, option).json(),
              $get: (option?: { config?: T }) =>
                fetch<Methods88['get']['resBody']>(prefix, `${prefix15}${PATH79}`, GET, option).json().then(r => r.body)
            },
            work_histories: {
              get: (option?: { query?: Methods89['get']['query'], config?: T }) =>
                fetch<Methods89['get']['resBody']>(prefix, `${prefix15}${PATH80}`, GET, option).json(),
              $get: (option?: { query?: Methods89['get']['query'], config?: T }) =>
                fetch<Methods89['get']['resBody']>(prefix, `${prefix15}${PATH80}`, GET, option).json().then(r => r.body)
            },
            work_history_summary: {
              get: (option?: { config?: T }) =>
                fetch<Methods90['get']['resBody']>(prefix, `${prefix15}${PATH81}`, GET, option).json(),
              $get: (option?: { config?: T }) =>
                fetch<Methods90['get']['resBody']>(prefix, `${prefix15}${PATH81}`, GET, option).json().then(r => r.body)
            },
            get: (option?: { config?: T }) =>
              fetch<Methods85['get']['resBody']>(prefix, `${prefix15}${PATH9}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods85['get']['resBody']>(prefix, `${prefix15}${PATH9}`, GET, option).json().then(r => r.body)
          }
        },
        download: {
          get: (option?: { query?: Methods91['get']['query'], config?: T }) =>
            fetch<Methods91['get']['resBody']>(prefix, PATH82, GET, option).blob(),
          $get: (option?: { query?: Methods91['get']['query'], config?: T }) =>
            fetch<Methods91['get']['resBody']>(prefix, PATH82, GET, option).blob().then(r => r.body)
        },
        get: (option?: { query?: Methods84['get']['query'], config?: T }) =>
          fetch<Methods84['get']['resBody']>(prefix, PATH76, GET, option).json(),
        $get: (option?: { query?: Methods84['get']['query'], config?: T }) =>
          fetch<Methods84['get']['resBody']>(prefix, PATH76, GET, option).json().then(r => r.body)
      },
      get: (option?: { query?: Methods44['get']['query'], config?: T }) =>
        fetch<Methods44['get']['resBody']>(prefix, PATH42, GET, option).json(),
      $get: (option?: { query?: Methods44['get']['query'], config?: T }) =>
        fetch<Methods44['get']['resBody']>(prefix, PATH42, GET, option).json().then(r => r.body),
      post: (option: { body: Methods44['post']['reqBody'], config?: T }) =>
        fetch<Methods44['post']['resBody']>(prefix, PATH42, POST, option).json(),
      $post: (option: { body: Methods44['post']['reqBody'], config?: T }) =>
        fetch<Methods44['post']['resBody']>(prefix, PATH42, POST, option).json().then(r => r.body)
    },
    educations: {
      training_for_corporations: {
        _id: (val17: string) => {
          const prefix17 = `${PATH83}${val17}`

          return {
            download_csv: {
              get: (option: { query: Methods94['get']['query'], config?: T }) =>
                fetch<Methods94['get']['resBody']>(prefix, `${prefix17}${PATH84}`, GET, option).blob(),
              $get: (option: { query: Methods94['get']['query'], config?: T }) =>
                fetch<Methods94['get']['resBody']>(prefix, `${prefix17}${PATH84}`, GET, option).blob().then(r => r.body)
            },
            update_status: {
              post: (option: { body: Methods95['post']['reqBody'], config?: T }) =>
                fetch<void>(prefix, `${prefix17}${PATH85}`, POST, option).send(),
              $post: (option: { body: Methods95['post']['reqBody'], config?: T }) =>
                fetch<void>(prefix, `${prefix17}${PATH85}`, POST, option).send().then(r => r.body)
            },
            validate: {
              get: (option?: { config?: T }) =>
                fetch<Methods96['get']['resBody']>(prefix, `${prefix17}${PATH86}`, GET, option).json(),
              $get: (option?: { config?: T }) =>
                fetch<Methods96['get']['resBody']>(prefix, `${prefix17}${PATH86}`, GET, option).json().then(r => r.body)
            },
            get: (option?: { config?: T }) =>
              fetch<Methods93['get']['resBody']>(prefix, `${prefix17}${PATH9}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods93['get']['resBody']>(prefix, `${prefix17}${PATH9}`, GET, option).json().then(r => r.body),
            post: (option: { body: Methods93['post']['reqBody'], config?: T }) =>
              fetch<Methods93['post']['resBody']>(prefix, `${prefix17}${PATH9}`, POST, option).json(),
            $post: (option: { body: Methods93['post']['reqBody'], config?: T }) =>
              fetch<Methods93['post']['resBody']>(prefix, `${prefix17}${PATH9}`, POST, option).json().then(r => r.body)
          }
        },
        get: (option?: { config?: T }) =>
          fetch<Methods92['get']['resBody']>(prefix, PATH83, GET, option).json(),
        $get: (option?: { config?: T }) =>
          fetch<Methods92['get']['resBody']>(prefix, PATH83, GET, option).json().then(r => r.body),
        post: (option: { body: Methods92['post']['reqBody'], config?: T }) =>
          fetch<Methods92['post']['resBody']>(prefix, PATH83, POST, option).json(),
        $post: (option: { body: Methods92['post']['reqBody'], config?: T }) =>
          fetch<Methods92['post']['resBody']>(prefix, PATH83, POST, option).json().then(r => r.body)
      }
    },
    localities: {
      _id: (val18: number) => {
        const prefix18 = `${PATH87}${val18}`

        return {
          get: (option?: { config?: T }) =>
            fetch<Methods98['get']['resBody']>(prefix, `${prefix18}${PATH9}`, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods98['get']['resBody']>(prefix, `${prefix18}${PATH9}`, GET, option).json().then(r => r.body)
        }
      },
      list_with_seirei: {
        get: (option?: { query?: Methods99['get']['query'], config?: T }) =>
          fetch<Methods99['get']['resBody']>(prefix, PATH88, GET, option).json(),
        $get: (option?: { query?: Methods99['get']['query'], config?: T }) =>
          fetch<Methods99['get']['resBody']>(prefix, PATH88, GET, option).json().then(r => r.body)
      },
      get: (option?: { query?: Methods97['get']['query'], config?: T }) =>
        fetch<Methods97['get']['resBody']>(prefix, PATH87, GET, option).json(),
      $get: (option?: { query?: Methods97['get']['query'], config?: T }) =>
        fetch<Methods97['get']['resBody']>(prefix, PATH87, GET, option).json().then(r => r.body)
    },
    orders: {
      _id: (val19: number) => {
        const prefix19 = `${PATH89}${val19}`

        return {
          avg_evaluations: {
            get: (option?: { config?: T }) =>
              fetch<Methods102['get']['resBody']>(prefix, `${prefix19}${PATH90}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods102['get']['resBody']>(prefix, `${prefix19}${PATH90}`, GET, option).json().then(r => r.body)
          },
          update_applied_order: {
            patch: (option: { body: Methods103['patch']['reqBody'], config?: T }) =>
              fetch<void>(prefix, `${prefix19}${PATH91}`, PATCH, option).send(),
            $patch: (option: { body: Methods103['patch']['reqBody'], config?: T }) =>
              fetch<void>(prefix, `${prefix19}${PATH91}`, PATCH, option).send().then(r => r.body)
          },
          get: (option?: { config?: T }) =>
            fetch<Methods101['get']['resBody']>(prefix, `${prefix19}${PATH9}`, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods101['get']['resBody']>(prefix, `${prefix19}${PATH9}`, GET, option).json().then(r => r.body),
          patch: (option: { body: Methods101['patch']['reqBody'], config?: T }) =>
            fetch<Methods101['patch']['resBody']>(prefix, `${prefix19}${PATH9}`, PATCH, option).json(),
          $patch: (option: { body: Methods101['patch']['reqBody'], config?: T }) =>
            fetch<Methods101['patch']['resBody']>(prefix, `${prefix19}${PATH9}`, PATCH, option).json().then(r => r.body)
        }
      },
      admin_orders: {
        _id: (val20: number) => {
          const prefix20 = `${PATH92}${val20}`

          return {
            get: (option?: { config?: T }) =>
              fetch<Methods105['get']['resBody']>(prefix, `${prefix20}${PATH9}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods105['get']['resBody']>(prefix, `${prefix20}${PATH9}`, GET, option).json().then(r => r.body)
          }
        },
        batch_update_is_point_increased: {
          post: (option: { body: Methods106['post']['reqBody'], config?: T }) =>
            fetch<void>(prefix, PATH93, POST, option).send(),
          $post: (option: { body: Methods106['post']['reqBody'], config?: T }) =>
            fetch<void>(prefix, PATH93, POST, option).send().then(r => r.body)
        },
        batch_update_status: {
          post: (option: { body: Methods107['post']['reqBody'], config?: T }) =>
            fetch<Methods107['post']['resBody']>(prefix, PATH94, POST, option).json(),
          $post: (option: { body: Methods107['post']['reqBody'], config?: T }) =>
            fetch<Methods107['post']['resBody']>(prefix, PATH94, POST, option).json().then(r => r.body)
        },
        fetch_desired_work_schedules: {
          get: (option: { query: Methods108['get']['query'], config?: T }) =>
            fetch<Methods108['get']['resBody']>(prefix, PATH95, GET, option).json(),
          $get: (option: { query: Methods108['get']['query'], config?: T }) =>
            fetch<Methods108['get']['resBody']>(prefix, PATH95, GET, option).json().then(r => r.body)
        },
        fetch_is_first_time_work: {
          post: (option: { body: Methods109['post']['reqBody'], config?: T }) =>
            fetch<Methods109['post']['resBody']>(prefix, PATH96, POST, option).json(),
          $post: (option: { body: Methods109['post']['reqBody'], config?: T }) =>
            fetch<Methods109['post']['resBody']>(prefix, PATH96, POST, option).json().then(r => r.body)
        },
        fetch_order_with_applications: {
          get: (option: { query: Methods110['get']['query'], config?: T }) =>
            fetch<Methods110['get']['resBody']>(prefix, PATH97, GET, option).json(),
          $get: (option: { query: Methods110['get']['query'], config?: T }) =>
            fetch<Methods110['get']['resBody']>(prefix, PATH97, GET, option).json().then(r => r.body)
        },
        get: (option?: { query?: Methods104['get']['query'], config?: T }) =>
          fetch<Methods104['get']['resBody']>(prefix, PATH92, GET, option).json(),
        $get: (option?: { query?: Methods104['get']['query'], config?: T }) =>
          fetch<Methods104['get']['resBody']>(prefix, PATH92, GET, option).json().then(r => r.body)
      },
      anonymous_evaluations: {
        _officeId: (val21: number) => {
          const prefix21 = `${PATH98}${val21}`

          return {
            count: {
              get: (option: { query: Methods112['get']['query'], config?: T }) =>
                fetch<Methods112['get']['resBody']>(prefix, `${prefix21}${PATH99}`, GET, option).json(),
              $get: (option: { query: Methods112['get']['query'], config?: T }) =>
                fetch<Methods112['get']['resBody']>(prefix, `${prefix21}${PATH99}`, GET, option).json().then(r => r.body)
            },
            update: {
              _evaluation_id: (val22: number) => {
                const prefix22 = `${prefix21}${PATH100}${val22}`

                return {
                  patch: (option: { body: Methods114['patch']['reqBody'], config?: T }) =>
                    fetch<void>(prefix, `${prefix22}${PATH9}`, PATCH, option).send(),
                  $patch: (option: { body: Methods114['patch']['reqBody'], config?: T }) =>
                    fetch<void>(prefix, `${prefix22}${PATH9}`, PATCH, option).send().then(r => r.body)
                }
              },
              patch: (option: { body: Methods113['patch']['reqBody'], config?: T }) =>
                fetch<void>(prefix, `${prefix21}${PATH100}`, PATCH, option).send(),
              $patch: (option: { body: Methods113['patch']['reqBody'], config?: T }) =>
                fetch<void>(prefix, `${prefix21}${PATH100}`, PATCH, option).send().then(r => r.body)
            },
            get: (option: { query: Methods111['get']['query'], config?: T }) =>
              fetch<Methods111['get']['resBody']>(prefix, `${prefix21}${PATH9}`, GET, option).json(),
            $get: (option: { query: Methods111['get']['query'], config?: T }) =>
              fetch<Methods111['get']['resBody']>(prefix, `${prefix21}${PATH9}`, GET, option).json().then(r => r.body)
          }
        }
      },
      applications: {
        _id: (val23: number) => {
          const prefix23 = `${PATH101}${val23}`

          return {
            application_conditions: {
              patch: (option: { body: Methods117['patch']['reqBody'], config?: T }) =>
                fetch<void>(prefix, `${prefix23}${PATH102}`, PATCH, option).send(),
              $patch: (option: { body: Methods117['patch']['reqBody'], config?: T }) =>
                fetch<void>(prefix, `${prefix23}${PATH102}`, PATCH, option).send().then(r => r.body)
            },
            cancel: {
              post: (option?: { config?: T }) =>
                fetch<Methods118['post']['resBody']>(prefix, `${prefix23}${PATH103}`, POST, option).json(),
              $post: (option?: { config?: T }) =>
                fetch<Methods118['post']['resBody']>(prefix, `${prefix23}${PATH103}`, POST, option).json().then(r => r.body)
            },
            finish_work: {
              post: (option: { body: Methods119['post']['reqBody'], config?: T }) =>
                fetch<Methods119['post']['resBody']>(prefix, `${prefix23}${PATH104}`, POST, option).json(),
              $post: (option: { body: Methods119['post']['reqBody'], config?: T }) =>
                fetch<Methods119['post']['resBody']>(prefix, `${prefix23}${PATH104}`, POST, option).json().then(r => r.body)
            },
            start_work: {
              post: (option: { body: Methods120['post']['reqBody'], config?: T }) =>
                fetch<Methods120['post']['resBody']>(prefix, `${prefix23}${PATH105}`, POST, option).json(),
              $post: (option: { body: Methods120['post']['reqBody'], config?: T }) =>
                fetch<Methods120['post']['resBody']>(prefix, `${prefix23}${PATH105}`, POST, option).json().then(r => r.body)
            },
            get: (option?: { config?: T }) =>
              fetch<Methods116['get']['resBody']>(prefix, `${prefix23}${PATH9}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods116['get']['resBody']>(prefix, `${prefix23}${PATH9}`, GET, option).json().then(r => r.body),
            patch: (option: { body: Methods116['patch']['reqBody'], config?: T }) =>
              fetch<Methods116['patch']['resBody']>(prefix, `${prefix23}${PATH9}`, PATCH, option).json(),
            $patch: (option: { body: Methods116['patch']['reqBody'], config?: T }) =>
              fetch<Methods116['patch']['resBody']>(prefix, `${prefix23}${PATH9}`, PATCH, option).json().then(r => r.body)
          }
        },
        can_apply: {
          post: (option: { body: Methods121['post']['reqBody'], config?: T }) =>
            fetch<Methods121['post']['resBody']>(prefix, PATH106, POST, option).json(),
          $post: (option: { body: Methods121['post']['reqBody'], config?: T }) =>
            fetch<Methods121['post']['resBody']>(prefix, PATH106, POST, option).json().then(r => r.body)
        },
        count_tasks: {
          get: (option: { query: Methods122['get']['query'], config?: T }) =>
            fetch<Methods122['get']['resBody']>(prefix, PATH107, GET, option).json(),
          $get: (option: { query: Methods122['get']['query'], config?: T }) =>
            fetch<Methods122['get']['resBody']>(prefix, PATH107, GET, option).json().then(r => r.body)
        },
        download_csv: {
          get: (option: { query: Methods123['get']['query'], config?: T }) =>
            fetch<Methods123['get']['resBody']>(prefix, PATH108, GET, option).blob(),
          $get: (option: { query: Methods123['get']['query'], config?: T }) =>
            fetch<Methods123['get']['resBody']>(prefix, PATH108, GET, option).blob().then(r => r.body)
        },
        summary: {
          get: (option?: { query?: Methods124['get']['query'], config?: T }) =>
            fetch<Methods124['get']['resBody']>(prefix, PATH109, GET, option).json(),
          $get: (option?: { query?: Methods124['get']['query'], config?: T }) =>
            fetch<Methods124['get']['resBody']>(prefix, PATH109, GET, option).json().then(r => r.body)
        },
        get: (option?: { query?: Methods115['get']['query'], config?: T }) =>
          fetch<Methods115['get']['resBody']>(prefix, PATH101, GET, option).json(),
        $get: (option?: { query?: Methods115['get']['query'], config?: T }) =>
          fetch<Methods115['get']['resBody']>(prefix, PATH101, GET, option).json().then(r => r.body),
        post: (option: { body: Methods115['post']['reqBody'], config?: T }) =>
          fetch<Methods115['post']['resBody']>(prefix, PATH101, POST, option).json(),
        $post: (option: { body: Methods115['post']['reqBody'], config?: T }) =>
          fetch<Methods115['post']['resBody']>(prefix, PATH101, POST, option).json().then(r => r.body)
      },
      attendance_corrections: {
        _id: (val24: number) => {
          const prefix24 = `${PATH110}${val24}`

          return {
            approve: {
              post: (option: { body: Methods125['post']['reqBody'], config?: T }) =>
                fetch<Methods125['post']['resBody']>(prefix, `${prefix24}${PATH111}`, POST, option).json(),
              $post: (option: { body: Methods125['post']['reqBody'], config?: T }) =>
                fetch<Methods125['post']['resBody']>(prefix, `${prefix24}${PATH111}`, POST, option).json().then(r => r.body)
            },
            reject: {
              post: (option: { body: Methods126['post']['reqBody'], config?: T }) =>
                fetch<Methods126['post']['resBody']>(prefix, `${prefix24}${PATH112}`, POST, option).json(),
              $post: (option: { body: Methods126['post']['reqBody'], config?: T }) =>
                fetch<Methods126['post']['resBody']>(prefix, `${prefix24}${PATH112}`, POST, option).json().then(r => r.body)
            }
          }
        }
      },
      calc_salary: {
        post: (option: { body: Methods127['post']['reqBody'], config?: T }) =>
          fetch<Methods127['post']['resBody']>(prefix, PATH113, POST, option).json(),
        $post: (option: { body: Methods127['post']['reqBody'], config?: T }) =>
          fetch<Methods127['post']['resBody']>(prefix, PATH113, POST, option).json().then(r => r.body)
      },
      cancel_confirmed: {
        post: (option: { body: Methods128['post']['reqBody'], config?: T }) =>
          fetch<void>(prefix, PATH114, POST, option).send(),
        $post: (option: { body: Methods128['post']['reqBody'], config?: T }) =>
          fetch<void>(prefix, PATH114, POST, option).send().then(r => r.body)
      },
      cancel_request: {
        get: (option?: { query?: Methods129['get']['query'], config?: T }) =>
          fetch<Methods129['get']['resBody']>(prefix, PATH115, GET, option).json(),
        $get: (option?: { query?: Methods129['get']['query'], config?: T }) =>
          fetch<Methods129['get']['resBody']>(prefix, PATH115, GET, option).json().then(r => r.body)
      },
      descriptions: {
        get: (option?: { query?: Methods130['get']['query'], config?: T }) =>
          fetch<Methods130['get']['resBody']>(prefix, PATH116, GET, option).json(),
        $get: (option?: { query?: Methods130['get']['query'], config?: T }) =>
          fetch<Methods130['get']['resBody']>(prefix, PATH116, GET, option).json().then(r => r.body)
      },
      evaluation_by_office: {
        download_csv: {
          get: (option: { query: Methods132['get']['query'], config?: T }) =>
            fetch<Methods132['get']['resBody']>(prefix, PATH118, GET, option).blob(),
          $get: (option: { query: Methods132['get']['query'], config?: T }) =>
            fetch<Methods132['get']['resBody']>(prefix, PATH118, GET, option).blob().then(r => r.body)
        },
        summary: {
          get: (option?: { query?: Methods133['get']['query'], config?: T }) =>
            fetch<Methods133['get']['resBody']>(prefix, PATH119, GET, option).json(),
          $get: (option?: { query?: Methods133['get']['query'], config?: T }) =>
            fetch<Methods133['get']['resBody']>(prefix, PATH119, GET, option).json().then(r => r.body)
        },
        post: (option: { body: Methods131['post']['reqBody'], config?: T }) =>
          fetch<Methods131['post']['resBody']>(prefix, PATH117, POST, option).json(),
        $post: (option: { body: Methods131['post']['reqBody'], config?: T }) =>
          fetch<Methods131['post']['resBody']>(prefix, PATH117, POST, option).json().then(r => r.body)
      },
      evaluation_by_worker: {
        download_csv: {
          get: (option: { query: Methods135['get']['query'], config?: T }) =>
            fetch<Methods135['get']['resBody']>(prefix, PATH121, GET, option).blob(),
          $get: (option: { query: Methods135['get']['query'], config?: T }) =>
            fetch<Methods135['get']['resBody']>(prefix, PATH121, GET, option).blob().then(r => r.body)
        },
        summary: {
          get: (option?: { query?: Methods136['get']['query'], config?: T }) =>
            fetch<Methods136['get']['resBody']>(prefix, PATH122, GET, option).json(),
          $get: (option?: { query?: Methods136['get']['query'], config?: T }) =>
            fetch<Methods136['get']['resBody']>(prefix, PATH122, GET, option).json().then(r => r.body)
        },
        get: (option?: { query?: Methods134['get']['query'], config?: T }) =>
          fetch<Methods134['get']['resBody']>(prefix, PATH120, GET, option).json(),
        $get: (option?: { query?: Methods134['get']['query'], config?: T }) =>
          fetch<Methods134['get']['resBody']>(prefix, PATH120, GET, option).json().then(r => r.body),
        post: (option: { body: Methods134['post']['reqBody'], config?: T }) =>
          fetch<Methods134['post']['resBody']>(prefix, PATH120, POST, option).json(),
        $post: (option: { body: Methods134['post']['reqBody'], config?: T }) =>
          fetch<Methods134['post']['resBody']>(prefix, PATH120, POST, option).json().then(r => r.body)
      },
      events: {
        count_current: {
          get: (option?: { query?: Methods138['get']['query'], config?: T }) =>
            fetch<Methods138['get']['resBody']>(prefix, PATH124, GET, option).json(),
          $get: (option?: { query?: Methods138['get']['query'], config?: T }) =>
            fetch<Methods138['get']['resBody']>(prefix, PATH124, GET, option).json().then(r => r.body)
        },
        get: (option?: { query?: Methods137['get']['query'], config?: T }) =>
          fetch<Methods137['get']['resBody']>(prefix, PATH123, GET, option).json(),
        $get: (option?: { query?: Methods137['get']['query'], config?: T }) =>
          fetch<Methods137['get']['resBody']>(prefix, PATH123, GET, option).json().then(r => r.body)
      },
      message_rooms: {
        count_unread_messages_to_office: {
          get: (option: { query: Methods140['get']['query'], config?: T }) =>
            fetch<Methods140['get']['resBody']>(prefix, PATH126, GET, option).json(),
          $get: (option: { query: Methods140['get']['query'], config?: T }) =>
            fetch<Methods140['get']['resBody']>(prefix, PATH126, GET, option).json().then(r => r.body)
        },
        read_messages_to_office: {
          patch: (option: { body: Methods141['patch']['reqBody'], config?: T }) =>
            fetch<void>(prefix, PATH127, PATCH, option).send(),
          $patch: (option: { body: Methods141['patch']['reqBody'], config?: T }) =>
            fetch<void>(prefix, PATH127, PATCH, option).send().then(r => r.body)
        },
        get: (option?: { query?: Methods139['get']['query'], config?: T }) =>
          fetch<Methods139['get']['resBody']>(prefix, PATH125, GET, option).json(),
        $get: (option?: { query?: Methods139['get']['query'], config?: T }) =>
          fetch<Methods139['get']['resBody']>(prefix, PATH125, GET, option).json().then(r => r.body)
      },
      minimum_rest_minutes: {
        get: (option?: { query?: Methods142['get']['query'], config?: T }) =>
          fetch<Methods142['get']['resBody']>(prefix, PATH128, GET, option).json(),
        $get: (option?: { query?: Methods142['get']['query'], config?: T }) =>
          fetch<Methods142['get']['resBody']>(prefix, PATH128, GET, option).json().then(r => r.body)
      },
      notice_of_employment_archive_list: {
        _id: (val25: number) => {
          const prefix25 = `${PATH129}${val25}`

          return {
            get: (option?: { config?: T }) =>
              fetch<Methods143['get']['resBody']>(prefix, `${prefix25}${PATH9}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods143['get']['resBody']>(prefix, `${prefix25}${PATH9}`, GET, option).json().then(r => r.body)
          }
        }
      },
      notice_of_employment_url: {
        _id: (val26: number) => {
          const prefix26 = `${PATH130}${val26}`

          return {
            archive: {
              _id: (val27: number) => {
                const prefix27 = `${prefix26}${PATH131}${val27}`

                return {
                  post: (option?: { config?: T }) =>
                    fetch<Methods145['post']['resBody']>(prefix, `${prefix27}${PATH9}`, POST, option).json(),
                  $post: (option?: { config?: T }) =>
                    fetch<Methods145['post']['resBody']>(prefix, `${prefix27}${PATH9}`, POST, option).json().then(r => r.body)
                }
              }
            },
            post: (option?: { config?: T }) =>
              fetch<Methods144['post']['resBody']>(prefix, `${prefix26}${PATH9}`, POST, option).json(),
            $post: (option?: { config?: T }) =>
              fetch<Methods144['post']['resBody']>(prefix, `${prefix26}${PATH9}`, POST, option).json().then(r => r.body)
          }
        },
        not_fixed: {
          post: (option: { body: Methods146['post']['reqBody'], config?: T }) =>
            fetch<Methods146['post']['resBody']>(prefix, PATH132, POST, option).json(),
          $post: (option: { body: Methods146['post']['reqBody'], config?: T }) =>
            fetch<Methods146['post']['resBody']>(prefix, PATH132, POST, option).json().then(r => r.body)
        },
        not_fixed_for_corporation: {
          post: (option: { body: Methods147['post']['reqBody'], config?: T }) =>
            fetch<Methods147['post']['resBody']>(prefix, PATH133, POST, option).json(),
          $post: (option: { body: Methods147['post']['reqBody'], config?: T }) =>
            fetch<Methods147['post']['resBody']>(prefix, PATH133, POST, option).json().then(r => r.body)
        }
      },
      office_to_worker_application_messages: {
        bulk_create: {
          post: (option: { body: Methods149['post']['reqBody'], config?: T }) =>
            fetch<Methods149['post']['resBody']>(prefix, PATH135, POST, option).json(),
          $post: (option: { body: Methods149['post']['reqBody'], config?: T }) =>
            fetch<Methods149['post']['resBody']>(prefix, PATH135, POST, option).json().then(r => r.body)
        },
        get: (option?: { query?: Methods148['get']['query'], config?: T }) =>
          fetch<Methods148['get']['resBody']>(prefix, PATH134, GET, option).json(),
        $get: (option?: { query?: Methods148['get']['query'], config?: T }) =>
          fetch<Methods148['get']['resBody']>(prefix, PATH134, GET, option).json().then(r => r.body),
        post: (option: { body: Methods148['post']['reqBody'], config?: T }) =>
          fetch<Methods148['post']['resBody']>(prefix, PATH134, POST, option).json(),
        $post: (option: { body: Methods148['post']['reqBody'], config?: T }) =>
          fetch<Methods148['post']['resBody']>(prefix, PATH134, POST, option).json().then(r => r.body)
      },
      offices: {
        _id: (val28: number) => {
          const prefix28 = `${PATH136}${val28}`

          return {
            application_message_templates: {
              _id: (val29: number) => {
                const prefix29 = `${prefix28}${PATH137}${val29}`

                return {
                  patch: (option: { body: Methods151['patch']['reqBody'], config?: T }) =>
                    fetch<Methods151['patch']['resBody']>(prefix, `${prefix29}${PATH9}`, PATCH, option).json(),
                  $patch: (option: { body: Methods151['patch']['reqBody'], config?: T }) =>
                    fetch<Methods151['patch']['resBody']>(prefix, `${prefix29}${PATH9}`, PATCH, option).json().then(r => r.body),
                  delete: (option?: { config?: T }) =>
                    fetch<void>(prefix, `${prefix29}${PATH9}`, DELETE, option).send(),
                  $delete: (option?: { config?: T }) =>
                    fetch<void>(prefix, `${prefix29}${PATH9}`, DELETE, option).send().then(r => r.body)
                }
              },
              get: (option?: { config?: T }) =>
                fetch<Methods150['get']['resBody']>(prefix, `${prefix28}${PATH137}`, GET, option).json(),
              $get: (option?: { config?: T }) =>
                fetch<Methods150['get']['resBody']>(prefix, `${prefix28}${PATH137}`, GET, option).json().then(r => r.body),
              post: (option: { body: Methods150['post']['reqBody'], config?: T }) =>
                fetch<void>(prefix, `${prefix28}${PATH137}`, POST, option).send(),
              $post: (option: { body: Methods150['post']['reqBody'], config?: T }) =>
                fetch<void>(prefix, `${prefix28}${PATH137}`, POST, option).send().then(r => r.body)
            },
            evaluation_message_templates: {
              _id: (val30: number) => {
                const prefix30 = `${prefix28}${PATH138}${val30}`

                return {
                  patch: (option: { body: Methods153['patch']['reqBody'], config?: T }) =>
                    fetch<Methods153['patch']['resBody']>(prefix, `${prefix30}${PATH9}`, PATCH, option).json(),
                  $patch: (option: { body: Methods153['patch']['reqBody'], config?: T }) =>
                    fetch<Methods153['patch']['resBody']>(prefix, `${prefix30}${PATH9}`, PATCH, option).json().then(r => r.body),
                  delete: (option?: { config?: T }) =>
                    fetch<void>(prefix, `${prefix30}${PATH9}`, DELETE, option).send(),
                  $delete: (option?: { config?: T }) =>
                    fetch<void>(prefix, `${prefix30}${PATH9}`, DELETE, option).send().then(r => r.body)
                }
              },
              get: (option?: { config?: T }) =>
                fetch<Methods152['get']['resBody']>(prefix, `${prefix28}${PATH138}`, GET, option).json(),
              $get: (option?: { config?: T }) =>
                fetch<Methods152['get']['resBody']>(prefix, `${prefix28}${PATH138}`, GET, option).json().then(r => r.body),
              post: (option: { body: Methods152['post']['reqBody'], config?: T }) =>
                fetch<void>(prefix, `${prefix28}${PATH138}`, POST, option).send(),
              $post: (option: { body: Methods152['post']['reqBody'], config?: T }) =>
                fetch<void>(prefix, `${prefix28}${PATH138}`, POST, option).send().then(r => r.body)
            }
          }
        }
      },
      operations: {
        get: (option?: { query?: Methods154['get']['query'], config?: T }) =>
          fetch<Methods154['get']['resBody']>(prefix, PATH139, GET, option).json(),
        $get: (option?: { query?: Methods154['get']['query'], config?: T }) =>
          fetch<Methods154['get']['resBody']>(prefix, PATH139, GET, option).json().then(r => r.body)
      },
      preview_notice_of_employment: {
        preview: {
          post: (option: { body: Methods155['post']['reqBody'], config?: T }) =>
            fetch<Methods155['post']['resBody']>(prefix, PATH140, POST, option).blob(),
          $post: (option: { body: Methods155['post']['reqBody'], config?: T }) =>
            fetch<Methods155['post']['resBody']>(prefix, PATH140, POST, option).blob().then(r => r.body)
        }
      },
      templates: {
        _id: (val31: number) => {
          const prefix31 = `${PATH141}${val31}`

          return {
            copy: {
              post: (option: { body: Methods158['post']['reqBody'], config?: T }) =>
                fetch<Methods158['post']['resBody']>(prefix, `${prefix31}${PATH142}`, POST, option).json(),
              $post: (option: { body: Methods158['post']['reqBody'], config?: T }) =>
                fetch<Methods158['post']['resBody']>(prefix, `${prefix31}${PATH142}`, POST, option).json().then(r => r.body)
            },
            get: (option?: { config?: T }) =>
              fetch<Methods157['get']['resBody']>(prefix, `${prefix31}${PATH9}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods157['get']['resBody']>(prefix, `${prefix31}${PATH9}`, GET, option).json().then(r => r.body),
            patch: (option: { body: Methods157['patch']['reqBody'], config?: T }) =>
              fetch<Methods157['patch']['resBody']>(prefix, `${prefix31}${PATH9}`, PATCH, option).json(),
            $patch: (option: { body: Methods157['patch']['reqBody'], config?: T }) =>
              fetch<Methods157['patch']['resBody']>(prefix, `${prefix31}${PATH9}`, PATCH, option).json().then(r => r.body)
          }
        },
        delete: {
          post: (option: { body: Methods159['post']['reqBody'], config?: T }) =>
            fetch<Methods159['post']['resBody']>(prefix, PATH143, POST, option).json(),
          $post: (option: { body: Methods159['post']['reqBody'], config?: T }) =>
            fetch<Methods159['post']['resBody']>(prefix, PATH143, POST, option).json().then(r => r.body)
        },
        get: (option?: { query?: Methods156['get']['query'], config?: T }) =>
          fetch<Methods156['get']['resBody']>(prefix, PATH141, GET, option).json(),
        $get: (option?: { query?: Methods156['get']['query'], config?: T }) =>
          fetch<Methods156['get']['resBody']>(prefix, PATH141, GET, option).json().then(r => r.body),
        post: (option: { body: Methods156['post']['reqBody'], config?: T }) =>
          fetch<Methods156['post']['resBody']>(prefix, PATH141, POST, option).json(),
        $post: (option: { body: Methods156['post']['reqBody'], config?: T }) =>
          fetch<Methods156['post']['resBody']>(prefix, PATH141, POST, option).json().then(r => r.body)
      },
      total_avg_evaluations_by_office: {
        get: (option: { query: Methods160['get']['query'], config?: T }) =>
          fetch<Methods160['get']['resBody']>(prefix, PATH144, GET, option).json(),
        $get: (option: { query: Methods160['get']['query'], config?: T }) =>
          fetch<Methods160['get']['resBody']>(prefix, PATH144, GET, option).json().then(r => r.body)
      },
      total_avg_evaluations_by_worker: {
        get: (option: { query: Methods161['get']['query'], config?: T }) =>
          fetch<Methods161['get']['resBody']>(prefix, PATH145, GET, option).json(),
        $get: (option: { query: Methods161['get']['query'], config?: T }) =>
          fetch<Methods161['get']['resBody']>(prefix, PATH145, GET, option).json().then(r => r.body)
      },
      total_avg_evaluations_by_worker_list: {
        get: (option: { query: Methods162['get']['query'], config?: T }) =>
          fetch<Methods162['get']['resBody']>(prefix, PATH146, GET, option).json(),
        $get: (option: { query: Methods162['get']['query'], config?: T }) =>
          fetch<Methods162['get']['resBody']>(prefix, PATH146, GET, option).json().then(r => r.body)
      },
      work_history: {
        get: (option?: { query?: Methods163['get']['query'], config?: T }) =>
          fetch<Methods163['get']['resBody']>(prefix, PATH147, GET, option).json(),
        $get: (option?: { query?: Methods163['get']['query'], config?: T }) =>
          fetch<Methods163['get']['resBody']>(prefix, PATH147, GET, option).json().then(r => r.body)
      },
      get: (option?: { query?: Methods100['get']['query'], config?: T }) =>
        fetch<Methods100['get']['resBody']>(prefix, PATH89, GET, option).json(),
      $get: (option?: { query?: Methods100['get']['query'], config?: T }) =>
        fetch<Methods100['get']['resBody']>(prefix, PATH89, GET, option).json().then(r => r.body),
      post: (option: { body: Methods100['post']['reqBody'], config?: T }) =>
        fetch<Methods100['post']['resBody']>(prefix, PATH89, POST, option).json(),
      $post: (option: { body: Methods100['post']['reqBody'], config?: T }) =>
        fetch<Methods100['post']['resBody']>(prefix, PATH89, POST, option).json().then(r => r.body)
    },
    prefectures: {
      get: (option?: { config?: T }) =>
        fetch<Methods164['get']['resBody']>(prefix, PATH148, GET, option).json(),
      $get: (option?: { config?: T }) =>
        fetch<Methods164['get']['resBody']>(prefix, PATH148, GET, option).json().then(r => r.body)
    },
    qualifications: {
      get: (option?: { query?: Methods165['get']['query'], config?: T }) =>
        fetch<Methods165['get']['resBody']>(prefix, PATH149, GET, option).json(),
      $get: (option?: { query?: Methods165['get']['query'], config?: T }) =>
        fetch<Methods165['get']['resBody']>(prefix, PATH149, GET, option).json().then(r => r.body)
    },
    system: {
      abtest: {
        register_event: {
          post: (option: { body: Methods166['post']['reqBody'], config?: T }) =>
            fetch<void>(prefix, PATH150, POST, option).send(),
          $post: (option: { body: Methods166['post']['reqBody'], config?: T }) =>
            fetch<void>(prefix, PATH150, POST, option).send().then(r => r.body)
        }
      },
      event: {
        register_event: {
          post: (option: { body: Methods167['post']['reqBody'], config?: T }) =>
            fetch<void>(prefix, PATH151, POST, option).send(),
          $post: (option: { body: Methods167['post']['reqBody'], config?: T }) =>
            fetch<void>(prefix, PATH151, POST, option).send().then(r => r.body)
        }
      },
      masters: {
        all: {
          get: (option?: { config?: T }) =>
            fetch<Methods168['get']['resBody']>(prefix, PATH152, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods168['get']['resBody']>(prefix, PATH152, GET, option).json().then(r => r.body)
        }
      }
    },
    users: {
      _id: (val32: string) => {
        const prefix32 = `${PATH153}${val32}`

        return {
          get: (option?: { config?: T }) =>
            fetch<Methods170['get']['resBody']>(prefix, `${prefix32}${PATH9}`, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods170['get']['resBody']>(prefix, `${prefix32}${PATH9}`, GET, option).json().then(r => r.body),
          patch: (option: { body: Methods170['patch']['reqBody'], config?: T }) =>
            fetch<Methods170['patch']['resBody']>(prefix, `${prefix32}${PATH9}`, PATCH, option).json(),
          $patch: (option: { body: Methods170['patch']['reqBody'], config?: T }) =>
            fetch<Methods170['patch']['resBody']>(prefix, `${prefix32}${PATH9}`, PATCH, option).json().then(r => r.body)
        }
      },
      get: (option?: { query?: Methods169['get']['query'], config?: T }) =>
        fetch<Methods169['get']['resBody']>(prefix, PATH153, GET, option).json(),
      $get: (option?: { query?: Methods169['get']['query'], config?: T }) =>
        fetch<Methods169['get']['resBody']>(prefix, PATH153, GET, option).json().then(r => r.body)
    },
    withholdings: {
      corporation: {
        slips: {
          eltax: {
            get: (option: { query: Methods171['get']['query'], config?: T }) =>
              fetch<Methods171['get']['resBody']>(prefix, PATH154, GET, option).blob(),
            $get: (option: { query: Methods171['get']['query'], config?: T }) =>
              fetch<Methods171['get']['resBody']>(prefix, PATH154, GET, option).blob().then(r => r.body)
          },
          excel: {
            get: (option: { query: Methods172['get']['query'], config?: T }) =>
              fetch<Methods172['get']['resBody']>(prefix, PATH155, GET, option).blob(),
            $get: (option: { query: Methods172['get']['query'], config?: T }) =>
              fetch<Methods172['get']['resBody']>(prefix, PATH155, GET, option).blob().then(r => r.body)
          },
          years: {
            get: (option: { query: Methods173['get']['query'], config?: T }) =>
              fetch<Methods173['get']['resBody']>(prefix, PATH156, GET, option).json(),
            $get: (option: { query: Methods173['get']['query'], config?: T }) =>
              fetch<Methods173['get']['resBody']>(prefix, PATH156, GET, option).json().then(r => r.body)
          }
        }
      }
    },
    workers: {
      _id: (val33: string) => {
        const prefix33 = `${PATH157}${val33}`

        return {
          cancel_rate: {
            get: (option?: { config?: T }) =>
              fetch<Methods174['get']['resBody']>(prefix, `${prefix33}${PATH158}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods174['get']['resBody']>(prefix, `${prefix33}${PATH158}`, GET, option).json().then(r => r.body)
          }
        }
      },
      user_experience: {
        get: (option?: { query?: Methods175['get']['query'], config?: T }) =>
          fetch<Methods175['get']['resBody']>(prefix, PATH159, GET, option).json(),
        $get: (option?: { query?: Methods175['get']['query'], config?: T }) =>
          fetch<Methods175['get']['resBody']>(prefix, PATH159, GET, option).json().then(r => r.body)
      },
      work_count: {
        _id: (val34: string) => {
          const prefix34 = `${PATH160}${val34}`

          return {
            get: (option?: { query?: Methods176['get']['query'], config?: T }) =>
              fetch<Methods176['get']['resBody']>(prefix, `${prefix34}${PATH9}`, GET, option).json(),
            $get: (option?: { query?: Methods176['get']['query'], config?: T }) =>
              fetch<Methods176['get']['resBody']>(prefix, `${prefix34}${PATH9}`, GET, option).json().then(r => r.body)
          }
        }
      }
    }
  }
}

export type ApiInstance = ReturnType<typeof api>
export default api
